.counter-component {
  border-style: solid;
  border-color: gray;
  background-color: white;
  border-width: 1px;
  border-radius: 5px;
  width: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

@media (max-width: 700px) {
  .counter-component {
    width: 70px;
    height: 30px;
  }
}

.counter-component.counter-component-disabled {
  border-color: silver;
}

.counter-component.counter-component-disabled .counter-component-count {
  color: silver;
}

.counter-component .counter-component-count {
  text-align: center;
  width: 50px;
}
