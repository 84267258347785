@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@300;400;500;700;800&display=swap);
.inactive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 20px);
}

@media (max-width: 700px) {
  .inactive {
    flex-direction: column;
  }
}

.inactive .inactive-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  height: 100%;
}

@media (max-width: 700px) {
  .inactive .inactive-left {
    display: none;
  }
}

.inactive .inactive-left .icon {
  max-height: 200px;
  max-width: 300px;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .inactive .inactive-left .icon {
    max-height: 100px;
    max-width: 200px;
  }
}

.inactive .inactive-right {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
}

@media (max-width: 700px) {
  .inactive .inactive-right {
    padding-top: 20px;
    width: 100%;
  }
}

.inactive .inactive-right .inactive-right-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 5rem;
  margin: 0 20px;
  text-align: left;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .inactive .inactive-right .inactive-right-title {
    text-align: center;
  }
}

@media (max-width: 700px) {
  .inactive .inactive-right .inactive-right-title {
    text-align: center;
  }
}

.inactive .inactive-right .inactive-right-subtitle {
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 1.5rem;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .inactive .inactive-right .inactive-right-subtitle {
    text-align: center;
  }
}

@media (max-width: 700px) {
  .inactive .inactive-right .inactive-right-subtitle {
    text-align: center;
  }
}

.inactive .inactive-right .inactive-right-subtitle a {
  text-decoration: none;
  color: #2B61FE;
}

.inactive .inactive-right .inactive-right-textfield {
  width: 90%;
}

.inactive .inactive-right .inactive-right-textfield .MuiOutlinedInput-root {
  margin-top: 5px;
  margin-bottom: 0;
}

.inactive .inactive-right .inactive-right-textfield .MuiOutlinedInput-root fieldset {
  border-color: black;
}

.inactive .inactive-right .inactive-right-textfield .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: black;
}

.inactive .inactive-right .inactive-submit-buttons {
  margin-top: 10px;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .inactive .inactive-right .inactive-submit-buttons {
    flex-direction: column;
    justify-content: space-around;
    height: 150px;
  }
}

.inactive .inactive-right .inactive-accordian {
  width: 80%;
  border-style: none;
  box-shadow: 0 2.5px 2px rgba(0, 0, 0, 0.03);
}

.inactive .inactive-right .inactive-accordian .inactive-accordian-details {
  display: flex;
  flex-direction: column;
}

@media (max-width: 700px) {
  .footer-seasons-container {
    display: none;
  }
}

@media (max-width: 700px) {
  .footer-articles-container {
    display: none;
  }
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-container .team-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 300px;
}

@media (max-width: 700px) {
  .footer-container .team-footer {
    flex-direction: column;
    justify-content: center;
  }
}

.footer-container .team-footer .team-footer-links {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 80%;
  width: 20%;
}

@media (max-width: 700px) {
  .footer-container .team-footer .team-footer-links {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .team-footer .team-footer-links {
    margin-left: 50px;
  }
}

.footer-container .team-footer .team-footer-links .team-footer-link {
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 500;
  text-decoration: none;
  color: white;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.footer-container .team-footer .team-footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 700px) {
  .footer-container .team-footer .team-footer-content {
    padding-top: 40px;
  }
}

.footer-container .team-footer .team-footer-content .icon {
  height: 120px;
  max-width: 80%;
  object-fit: contain;
}

.footer-container .team-footer .team-footer-content .name-icon {
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 500;
  text-decoration: none;
  color: white;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.footer-container .team-footer .team-footer-social {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 70%;
  width: 20%;
}

@media (max-width: 700px) {
  .footer-container .team-footer .team-footer-social {
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0;
    height: 30%;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .team-footer .team-footer-social {
    margin-right: 50px;
  }
}

.footer-container .optimx-ad {
  background-color: #202020;
  z-index: 1000;
}

.footer-container .optimx-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  background-color: #101010;
}

.footer-container .optimx-footer .optimx-footer-copyright {
  font-weight: 800;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 60%;
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .optimx-footer-copyright {
    font-size: 0.8rem;
    margin-left: 20px;
    width: 30%;
    justify-content: center;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .optimx-footer-copyright {
    font-size: 1rem;
    margin-left: 50px;
    width: 30%;
    justify-content: center;
  }
}

.footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements {
    flex-direction: column;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements {
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements .optimx-footer-agreement {
    font-size: 0.7rem !important;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements .optimx-footer-agreement {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements .optimx-footer-agreement-slash {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements .optimx-footer-agreement-slash {
    display: none;
  }
}

.footer-container .optimx-footer .icon-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 60%;
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .icon-container {
    margin-right: 20px;
    width: 35%;
    justify-content: flex-end;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .icon-container {
    margin-right: 50px;
    width: 35%;
    justify-content: flex-end;
  }
}

.footer-container .optimx-footer .icon-container .icon {
  height: 80px;
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .icon-container .icon {
    height: 50px;
    margin-right: 5px;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .icon-container .icon {
    height: 80px;
    margin-right: 5px;
  }
}

.footer-container .optimx-footer .icon-container .name {
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 800;
  text-decoration: none;
  color: white;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .icon-container .name {
    font-size: 0.7rem;
    text-align: right;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .icon-container .name {
    font-size: 1rem;
    text-align: right;
  }
}

.seasons-component1-container {
  height: 100%;
}

.seasons-component1-container .seasons-component1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-evenly;
}

.seasons-component1-container .seasons-component1 .seasons-component1-season {
  height: 240px;
  width: 360px;
}

.season-thumbnail1-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.season-thumbnail1-container:hover {
  cursor: pointer;
}

.season-thumbnail1-container .season-thumbnail1-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.season-thumbnail1-container .season-thumbnail1-text-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);
}

.title2-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 75px;
}

.title2-container .title2-text:hover {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .title2-container .title2-text {
    font-size: 2.5rem;
  }
}

@media (max-width: 700px) {
  .title2-container .title2-text {
    font-size: 2rem;
  }
}

.articles-component1-container {
  height: 100%;
}

.articles-component1-container .articles-component1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-evenly;
}

.articles-component1-container .articles-component1 .articles-component1-article {
  height: 240px;
  width: 360px;
}

.article-thumbnail3-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.article-thumbnail3-container:hover {
  cursor: pointer;
}

.article-thumbnail3-container .article-thumbnail3-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.article-thumbnail3-container .article-thumbnail3-text-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0) 100%);
}

.article-thumbnail3-container .article-thumbnail3-text-container:hover {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0.7) 100%);
}

.article-thumbnail3-container .article-thumbnail3-text-container:hover .article-thumbnail3-text {
  display: none;
}

.article-thumbnail3-container .article-thumbnail3-text-container:hover .article-thumbnail3-button {
  display: block;
}

.article-thumbnail3-container .article-thumbnail3-text-container .article-thumbnail3-button {
  margin: auto;
  display: none;
}

.title3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 100px;
}

@media (max-width: 700px) {
  .title3 .title3-title {
    margin-left: 0px !important;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .title3 .title3-title {
    margin-left: 50px !important;
  }
}

.title3 .title3-logo {
  height: 72px;
}

@media (max-width: 700px) {
  .title3 .title3-logo {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .title3 .title3-logo {
    margin-left: 50px !important;
  }
}

.sponsors-component4-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 1200px) {
  .sponsors-component4-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.sponsors-component4-container .sponsors-component4 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  min-height: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1200px) {
  .sponsors-component4-container .sponsors-component4 {
    width: 100%;
  }
}

.sponsors-component4-container .sponsors-component4 .sponsors-component4-sponsor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30%;
}

.sponsors-component4-container .sponsors-component4 .sponsors-component4-sponsor :hover {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .sponsors-component4-container .sponsors-component4 .sponsors-component4-sponsor {
    width: 40%;
  }
}

.sponsors-component4-container .sponsors-component4 .sponsors-component4-sponsor .sponsors-component4-sponsor-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 100px;
  max-width: 300px;
  object-fit: contain;
}

@media (max-width: 700px) {
  .sponsors-component4-container .sponsors-component4 .sponsors-component4-sponsor .sponsors-component4-sponsor-logo {
    height: auto;
    width: 100%;
    object-fit: contain;
  }
}

.sponsors-component4-container .sponsors-component4 .sponsors-component4-sponsor .sponsors-component4-sponsor-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.navbar-container2 {
  margin: 0;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: height 0.15s ease-in-out;
}

.navbar-container2 p {
  margin: 0;
}

@media (max-width: 700px) {
  .navbar-container2 {
    height: 150px;
  }
}

@media (min-width: 1200px) {
  .navbar-container2.navbar-container2-scrolled {
    height: 100px;
  }
  .navbar-container2.navbar-container2-scrolled .navbar-whitebar-content {
    font-size: 1.1rem !important;
  }
}

.navbar-container2 .navbar-navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 160px;
  transition: height 0.15s ease-in-out;
}

@media (min-width: 1200px) {
  .navbar-container2 .navbar-navbar.navbar-navbar-scrolled {
    height: 100px;
  }
  .navbar-container2 .navbar-navbar.navbar-navbar-scrolled .nav-item {
    height: 50px !important;
    font-weight: 400 !important;
  }
  .navbar-container2 .navbar-navbar.navbar-navbar-scrolled .navbar-whitebar::after {
    height: 0px !important;
  }
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar {
    height: 110px;
  }
}

.navbar-container2 .navbar-navbar .icon-container {
  height: 100%;
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1400px) {
  .navbar-container2 .navbar-navbar .icon-container {
    width: 15%;
  }
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .icon-container {
    width: 30%;
  }
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .icon-container {
    display: none;
  }
}

.navbar-container2 .navbar-navbar .icon-container .icon {
  max-height: 70%;
  max-width: 90%;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  height: 100%;
}

@media (max-width: 1400px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars {
    width: 85%;
  }
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars {
    width: 100%;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar {
  background-color: white;
  width: 100%;
  height: 50%;
  position: relative;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar::before {
  height: 100%;
  width: 20px;
  bottom: 0;
  content: '';
  position: absolute;
  left: 5%;
  top: 0;
  -webkit-transform: skew(-45deg, 0);
          transform: skew(-45deg, 0);
  z-index: 100;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar::before {
    left: 7%;
  }
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar::before {
    display: none;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar::after {
  height: 100%;
  width: 1%;
  bottom: 0;
  content: '';
  position: absolute;
  left: 0px;
  top: 0;
  height: 0px;
  border-right: 80px solid transparent;
  border-top: 80px solid #FFFFFF;
  z-index: 100;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar::after {
    display: none;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content {
  z-index: 101;
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Alegreya Sans', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  text-transform: uppercase;
  font-size: 1.4rem;
  font-family: "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 800;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content {
    font-size: 1rem;
    justify-content: center;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content .navbar-whitebar-teamname {
  margin-left: 10%;
  text-align: center;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content .navbar-whitebar-teamname {
    margin-left: 15%;
    margin-right: 10%;
  }
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content .navbar-whitebar-teamname {
    margin-left: 0;
    margin-right: 0;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content .navbar-whitebar-store {
  margin-right: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content .navbar-whitebar-store {
    display: none;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 101;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems {
    justify-content: space-between;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .mobile-icon-container {
  display: none;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .mobile-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    max-width: 77%;
    height: 100%;
    padding-left: 20px;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .mobile-icon-container .mobile-icon {
  height: 40px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-normal {
  max-width: 85vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-normal {
    display: none;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-normal .nav-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.2);
  border-right-width: 1px;
  height: 80px;
  line-height: 1;
  border-radius: 0;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  transition: height 0.15s ease-in-out;
}

@media (max-width: 1400px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-normal .nav-item {
    font-size: 0.8rem;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-normal .nav-item:hover {
  background-color: white;
  color: black !important;
  transition-duration: 0.5s;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-normal .nav-item .nav-item-with-drop-down {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down {
  display: none;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down {
    display: block;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down .nav-item {
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 {
  display: none;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-burger-button {
  position: fixed;
  width: 28px;
  height: 28px;
  right: 30px;
  top: 69px;
  -webkit-tap-highlight-color: transparent;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-burger-button {
    top: 107px;
    right: 30px;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-burger-bars {
  background: white;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-cross-button {
  width: 30px;
  height: 30px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-cross {
  background: black;
  display: none;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-overlay {
  background: transparent !important;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-menu-wrap {
  position: fixed;
  top: 150px;
  height: calc(100% - 150px) !important;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-menu-wrap {
    top: 200px;
    height: calc(100% - 200px) !important;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-menu {
  background: #f0f0f0;
  width: 100%;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-item-list {
  height: 100% !important;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .nav-items-accordinan {
  position: unset;
  border-style: none;
  box-shadow: none;
  background-color: transparent;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .nav-items-accordinan .nav-items-accordinan-summary {
  border-style: none;
  padding-left: 0px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .nav-items-accordinan .nav-items-accordinan-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .accordian-nav-item-accordinan {
  position: unset;
  border-style: none;
  box-shadow: none;
  background-color: transparent;
  padding-left: 20px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .accordian-nav-item-accordinan .accordian-nav-item-accordinan-summary {
  border-style: none;
  padding-left: 0px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .accordian-nav-item-accordinan .accordian-nav-item-accordinan-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .nav-item {
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  padding-left: 40px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .normal-nav-item {
  display: flex;
  justify-content: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .accordian-nav-item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 60px;
  width: 100%;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .nav-items-drop-down2-logo1 {
  width: 0px;
  height: 1px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .nav-items-drop-down2-logo2 {
  padding-top: 50px;
  padding-bottom: 120px;
  width: 100px;
  height: 100px;
  object-fit: contain;
  object-position: 50% 50%;
  margin-left: auto;
  margin-right: auto;
  outline: none;
}

.navbar-container2 .navbar-socials-container {
  background-color: white;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.04);
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 1200px) {
  .navbar-container2 .navbar-socials-container.navbar-socials-container-scrolled {
    display: none;
  }
}

.navbar-container2 .navbar-socials-container .navbar-socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-left: 30px;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-socials-container .navbar-socials {
    margin-left: 0px;
  }
}

.navbar-container2 .navbar-socials-container .navbar-socials .navbar-socials-buttons {
  background-color: transparent;
  cursor: pointer;
  border-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-socials-container .navbar-socials .navbar-socials-buttons {
    padding: 0 10px;
  }
}

.navbar-container2 .navbar-socials-container .navbar-socials .navbar-socials-buttons .navbar-socials-icon {
  height: 19px;
  margin-right: 20px;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-socials-container .navbar-socials .navbar-socials-buttons .navbar-socials-icon {
    margin-right: 5px;
  }
}

.navbar-container2 .navbar-socials-container .navbar-socials .navbar-socials-buttons p {
  font-family: 'Alegreya Sans', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-socials-container .navbar-socials .navbar-socials-buttons p {
    display: none;
  }
}

.navbar-container2 .navbar-socials-container .navbar-shop-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 30px;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-socials-container .navbar-shop-container {
    margin-right: 0px;
  }
}

.navbar-container2 .navbar-socials-container .navbar-shop-container .navbar-shop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
  border-style: none;
  background-color: transparent;
}

.navbar-container2 .navbar-socials-container .navbar-shop-container .navbar-shop p {
  font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
  font-size: 15px;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-socials-container .navbar-shop-container .navbar-shop {
    padding: 0 5px;
  }
  .navbar-container2 .navbar-socials-container .navbar-shop-container .navbar-shop p {
    display: none;
  }
}

@media (max-width: 1200px) {
  .nav-item-cart .MuiBadge-badge {
    color: black !important;
    background-color: white !important;
  }
}

.nav-item-cart:hover {
  cursor: pointer;
}

.nav-item-cart-drawer .MuiPaper-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 400px;
  background: #f0f0f0;
}

@media (max-width: 700px) {
  .nav-item-cart-drawer .MuiPaper-root {
    width: 100%;
  }
}

.nav-item-cart-drawer .nav-item-cart-topic {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  min-height: 64px;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .nav-item-cart-drawer .nav-item-cart-topic {
    min-height: 64px;
  }
}

@media (max-width: 700px) {
  .nav-item-cart-drawer .nav-item-cart-topic {
    min-height: 56px;
  }
}

.nav-item-cart-drawer .nav-item-cart-price {
  width: 280px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.nav-item-cart-drawer .nav-item-cart-item {
  padding: 20px 50px;
  max-width: calc(100% - 100px);
  min-width: calc(100% - 100px);
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom-style: solid;
  border-width: 1px;
  font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}

.nav-item-cart-drawer .nav-item-cart-item .nav-item-cart-item-label {
  text-align: left;
  font-weight: 300;
  font-size: 12px;
  border-style: solid;
  border-width: 1px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 10px;
  border-radius: 2px;
}

.nav-item-cart-drawer .nav-item-cart-item .nav-item-cart-item-image {
  padding: 10px 0px;
  max-height: 100px;
  border-radius: 10px;
  overflow: hidden;
  object-fit: contain;
  margin-left: 0;
  margin-right: auto;
}

.nav-item-cart-drawer .nav-item-cart-item .nav-item-cart-item-title {
  text-align: left;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 0px;
  border-radius: 2px;
  text-transform: capitalize;
  line-height: 20px;
  letter-spacing: 0;
}

.nav-item-cart-drawer .nav-item-cart-item .nav-item-cart-item-quantity {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.nav-item-cart-drawer .nav-item-cart-item .nav-item-cart-item-price {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 0px;
  border-radius: 2px;
  text-transform: capitalize;
  line-height: 20px;
  letter-spacing: 0;
}

.nav-item-cart-drawer .nav-item-cart-item .nav-item-cart-item-subprice {
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 0px;
  border-radius: 2px;
  text-transform: capitalize;
  line-height: 20px;
  letter-spacing: 0;
}

.textfield-primary-color {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 10px;
}

.textfield-primary-color .textfield-title {
  text-transform: uppercase;
}

.textfield-primary-color p {
  color: gray;
}

.textfield-primary-color .MuiOutlinedInput-root {
  background-color: #f8f8f8;
  margin-top: 5px;
  margin-bottom: 0;
}

.textfield-primary-color .MuiOutlinedInput-root fieldset {
  border-color: black;
  border: none;
}

.textfield-primary-color .MuiOutlinedInput-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.textfield-primary-color .MuiOutlinedInput-root.Mui-error {
  border-color: red;
  border-style: solid;
  border-width: 1px;
}

.half-width {
  width: 48%;
}

@media (max-width: 700px) {
  .half-width {
    width: 100%;
  }
}

.tickets-component1-container-container {
  max-width: 1200px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .tickets-component1-container-container .tickets-component1-container {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-title {
  font-size: 3.86rem;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-title {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-image {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-image {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-text {
  overflow: hidden;
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-text a {
  word-break: break-all;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-text img {
    width: 300px;
  }
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-text figcaption {
    max-width: 300px;
  }
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-text iframe {
    max-width: 300px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-text table {
  border-collapse: collapse;
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-text table td {
  border-style: solid;
  border-width: 1px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-text {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 7px;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 600;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table {
    font-size: 0.8rem;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: lightgray;
  color: white;
  padding: 20px 40px;
  border-radius: 7px 7px 0 0;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container {
    padding: 10px 10px;
    justify-content: space-around;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-description {
  width: 20%;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-description {
    width: 80px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-price {
  width: 20%;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-price {
    width: 50px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-quantity {
  width: 10%;
  min-width: 130px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-quantity {
    width: 70px;
    min-width: 70px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-total {
  width: 10%;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-total {
    width: 50px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top-style: solid;
  border-width: 1px;
  margin-top: -1px;
  border-color: silver;
  padding: 10px 40px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container {
    padding: 10px 10px;
    justify-content: space-around;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-description {
  width: 20%;
  text-transform: none;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-description {
    width: 80px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-price {
  width: 20%;
  font-size: 1rem;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-price {
    font-size: 0.8rem;
    width: 50px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-quantity {
  width: 10%;
  min-width: 130px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-quantity {
    width: 70px;
    min-width: 70px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-total {
  width: 10%;
  font-size: 1rem;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-total {
    font-size: 0.8rem;
    width: 50px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-subprice-footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-subprice-footer-container .tickets-component1-subprice-footer-total-label {
  width: 30%;
  padding-top: 2px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-subprice-footer-container .tickets-component1-subprice-footer-total-label {
    width: 70%;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-subprice-footer-container .tickets-component1-subprice-footer-total {
  width: 10%;
  font-size: 1.4rem;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-subprice-footer-container .tickets-component1-subprice-footer-total {
    width: 30%;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-price-footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 40px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-price-footer-container {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-price-footer-container .tickets-component1-price-footer-total-label {
  width: 10%;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-price-footer-container .tickets-component1-price-footer-total-label {
    width: 50%;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-price-footer-container .tickets-component1-price-footer-total {
  width: 10%;
  font-size: 1.7rem;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-price-footer-container .tickets-component1-price-footer-total {
    width: 30%;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 300px;
  height: 100px;
  margin-left: auto;
  margin-right: 20px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-buttons {
    align-items: center;
    margin-right: auto;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-icon {
  height: 42px;
}

.pop-up-content-ticket-customer {
  height: 300px !important;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: space-evenly !important;
}

.counter-component {
  border-style: solid;
  border-color: gray;
  background-color: white;
  border-width: 1px;
  border-radius: 5px;
  width: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

@media (max-width: 700px) {
  .counter-component {
    width: 70px;
    height: 30px;
  }
}

.counter-component.counter-component-disabled {
  border-color: silver;
}

.counter-component.counter-component-disabled .counter-component-count {
  color: silver;
}

.counter-component .counter-component-count {
  text-align: center;
  width: 50px;
}

.fab-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 120px;
  position: fixed;
  top: auto;
  right: 0;
  left: auto;
  margin-right: -20px;
  transition: all 0.4s;
  z-index: 101;
  border-radius: 15px;
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}

.fab-component.hide {
  right: -300px;
  transition: all 0.4s;
}

.fab-component.ticket {
  bottom: 10px;
}

.fab-component.store {
  bottom: 80px;
}

.fab-component:hover {
  cursor: pointer;
}

@media (max-width: 700px) {
  .fab-component {
    display: none;
  }
}

html body {
  font-family: "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  color: #171d28;
  background-color: white;
  margin: 0;
}

html body header {
  position: fixed;
  margin: 0;
  top: 0;
  height: 200px;
  z-index: 101;
}

@media (max-width: 700px) {
  html body header {
    height: 150px;
  }
}

html body main {
  min-height: calc(100vh - 78px);
  margin-top: 200px;
}

@media (max-width: 700px) {
  html body main {
    margin-top: 150px;
  }
}

html body footer {
  position: relative;
  margin-bottom: 0;
  width: 100%;
  clear: both;
  z-index: 100;
}

html body .heading-1 {
  text-align: start;
  font-size: 1.75rem;
  color: gray;
  margin-top: 20px;
  margin-bottom: 10px;
}

html body .heading-2 {
  text-align: start;
  font-size: 1rem;
  font-weight: 300;
  color: gray;
  margin-top: 20px;
  margin-bottom: 10px;
}

html body .x-center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

html body .full-width {
  width: 100%;
}

html body .curve-border-0 {
  border-radius: 0;
}

html body .curve-border-2px {
  border-radius: 2px;
}

@media (max-width: 700px) {
  html body .curve-border-2px {
    border-radius: 0;
  }
}

html body .curve-border-5px {
  border-radius: 5px;
}

@media (max-width: 700px) {
  html body .curve-border-5px {
    border-radius: 0;
  }
}

html body .curve-border-10px {
  border-radius: 10px;
}

@media (max-width: 700px) {
  html body .curve-border-10px {
    border-radius: 0;
  }
}

html body .curve-border-15px {
  border-radius: 15px;
}

@media (max-width: 700px) {
  html body .curve-border-15px {
    border-radius: 0;
  }
}

html body .curve-border-20px {
  border-radius: 20px;
}

@media (max-width: 700px) {
  html body .curve-border-20px {
    border-radius: 0;
  }
}

html body .curve-border-30px {
  border-radius: 30px;
}

@media (max-width: 700px) {
  html body .curve-border-30px {
    border-radius: 0;
  }
}

html body .curve-border-40px {
  border-radius: 40px;
}

@media (max-width: 700px) {
  html body .curve-border-40px {
    border-radius: 0;
  }
}

html body .box-shadow-black {
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.07);
}

html body .box-shadow-black:hover {
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
}

html body .no-box-shadow-black {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

html body .box-shadow-black-intense {
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.8);
}

html body .m-t-0 {
  margin-top: 0;
}

html body .m-l-0 {
  margin-left: 0;
}

html body .m-r-0 {
  margin-right: 0;
}

html body .m-b-0 {
  margin-bottom: 0;
}

html body .p-t-0 {
  padding-top: 0;
}

html body .p-l-0 {
  padding-left: 0;
}

html body .p-r-0 {
  padding-right: 0;
}

html body .p-b-0 {
  padding-bottom: 0;
}

html body .m-t-3px {
  margin-top: 3px;
}

html body .m-l-3px {
  margin-left: 3px;
}

html body .m-r-3px {
  margin-right: 3px;
}

html body .m-b-3px {
  margin-bottom: 3px;
}

html body .p-t-3px {
  padding-top: 3px;
}

html body .p-l-3px {
  padding-left: 3px;
}

html body .p-r-3px {
  padding-right: 3px;
}

html body .p-b-3px {
  padding-bottom: 3px;
}

html body .m-t-5px {
  margin-top: 5px;
}

html body .m-b-5px {
  margin-bottom: 5px;
}

html body .m-l-5px {
  margin-left: 5px;
}

html body .m-r-5px {
  margin-right: 5px;
}

html body .p-t-5px {
  padding-top: 5px;
}

html body .p-b-5px {
  padding-bottom: 5px;
}

html body .p-l-5px {
  padding-left: 5px;
}

html body .p-r-5px {
  padding-right: 5px;
}

html body .m-t-10px {
  margin-top: 10px;
}

html body .m-b-10px {
  margin-bottom: 10px;
}

html body .m-l-10px {
  margin-left: 10px;
}

html body .m-r-10px {
  margin-right: 10px;
}

html body .p-t-10px {
  padding-top: 10px;
}

html body .p-b-10px {
  padding-bottom: 10px;
}

html body .p-l-10px {
  padding-left: 10px;
}

html body .p-r-10px {
  padding-right: 10px;
}

html body .m-t-15px {
  margin-top: 15px;
}

html body .m-b-15px {
  margin-bottom: 15px;
}

html body .m-l-15px {
  margin-left: 15px;
}

html body .m-r-15px {
  margin-right: 15px;
}

html body .p-t-15px {
  padding-top: 15px;
}

html body .p-b-15px {
  padding-bottom: 15px;
}

html body .p-l-15px {
  padding-left: 15px;
}

html body .p-r-15px {
  padding-right: 15px;
}

html body .m-t-20px {
  margin-top: 20px;
}

html body .m-b-20px {
  margin-bottom: 20px;
}

html body .m-l-20px {
  margin-left: 20px;
}

html body .m-r-20px {
  margin-right: 20px;
}

html body .p-t-20px {
  padding-top: 20px;
}

html body .p-b-20px {
  padding-bottom: 20px;
}

html body .p-l-20px {
  padding-left: 20px;
}

html body .p-r-20px {
  padding-right: 20px;
}

html body .m-t-25px {
  margin-top: 25px;
}

html body .m-b-25px {
  margin-bottom: 25px;
}

html body .m-l-25px {
  margin-left: 25px;
}

html body .m-r-25px {
  margin-right: 25px;
}

html body .p-t-25px {
  padding-top: 25px;
}

html body .p-b-25px {
  padding-bottom: 25px;
}

html body .p-l-25px {
  padding-left: 25px;
}

html body .p-r-25px {
  padding-right: 25px;
}

html body .m-t-30px {
  margin-top: 30px;
}

html body .m-b-30px {
  margin-bottom: 30px;
}

html body .m-l-30px {
  margin-left: 30px;
}

html body .m-r-30px {
  margin-right: 30px;
}

html body .p-t-30px {
  padding-top: 30px;
}

html body .p-b-30px {
  padding-bottom: 30px;
}

html body .p-l-30px {
  padding-left: 30px;
}

html body .p-r-30px {
  padding-right: 30px;
}

html body .m-t-35px {
  margin-top: 35px;
}

html body .m-b-35px {
  margin-bottom: 35px;
}

html body .m-l-35px {
  margin-left: 35px;
}

html body .m-r-35px {
  margin-right: 35px;
}

html body .p-t-35px {
  padding-top: 35px;
}

html body .p-b-35px {
  padding-bottom: 35px;
}

html body .p-l-35px {
  padding-left: 35px;
}

html body .p-r-35px {
  padding-right: 35px;
}

html body .m-t-40px {
  margin-top: 40px;
}

html body .m-b-40px {
  margin-bottom: 40px;
}

html body .m-l-40px {
  margin-left: 40px;
}

html body .m-r-40px {
  margin-right: 40px;
}

html body .p-t-40px {
  padding-top: 40px;
}

html body .p-b-40px {
  padding-bottom: 40px;
}

html body .p-l-40px {
  padding-left: 40px;
}

html body .p-r-40px {
  padding-right: 40px;
}

html body .m-t-45px {
  margin-top: 45px;
}

html body .m-b-45px {
  margin-bottom: 45px;
}

html body .m-l-45px {
  margin-left: 45px;
}

html body .m-r-45px {
  margin-right: 45px;
}

html body .p-t-45px {
  padding-top: 45px;
}

html body .p-b-45px {
  padding-bottom: 45px;
}

html body .p-l-45px {
  padding-left: 45px;
}

html body .p-r-45px {
  padding-right: 45px;
}

html body .m-t-50px {
  margin-top: 50px;
}

html body .m-b-50px {
  margin-bottom: 50px;
}

html body .m-l-50px {
  margin-left: 50px;
}

html body .m-r-50px {
  margin-right: 50px;
}

html body .p-t-50px {
  padding-top: 50px;
}

html body .p-b-50px {
  padding-bottom: 50px;
}

html body .p-l-50px {
  padding-left: 50px;
}

html body .p-r-50px {
  padding-right: 50px;
}

html body .m-t-55px {
  margin-top: 55px;
}

html body .m-b-55px {
  margin-bottom: 55px;
}

html body .m-l-55px {
  margin-left: 55px;
}

html body .m-r-55px {
  margin-right: 55px;
}

html body .p-t-55px {
  padding-top: 55px;
}

html body .p-b-55px {
  padding-bottom: 55px;
}

html body .p-l-55px {
  padding-left: 55px;
}

html body .p-r-55px {
  padding-right: 55px;
}

html body .m-t-60px {
  margin-top: 60px;
}

html body .m-b-60px {
  margin-bottom: 60px;
}

html body .m-l-60px {
  margin-left: 60px;
}

html body .m-r-60px {
  margin-right: 60px;
}

html body .p-t-60px {
  padding-top: 60px;
}

html body .p-b-60px {
  padding-bottom: 60px;
}

html body .p-l-60px {
  padding-left: 60px;
}

html body .p-r-60px {
  padding-right: 60px;
}

html body .m-t-65px {
  margin-top: 65px;
}

html body .m-b-65px {
  margin-bottom: 65px;
}

html body .m-l-65px {
  margin-left: 65px;
}

html body .m-r-65px {
  margin-right: 65px;
}

html body .p-t-65px {
  padding-top: 65px;
}

html body .p-b-65px {
  padding-bottom: 65px;
}

html body .p-l-65px {
  padding-left: 65px;
}

html body .p-r-65px {
  padding-right: 65px;
}

html body .m-t-70px {
  margin-top: 70px;
}

html body .m-b-70px {
  margin-bottom: 70px;
}

html body .m-l-70px {
  margin-left: 70px;
}

html body .m-r-70px {
  margin-right: 70px;
}

html body .p-t-70px {
  padding-top: 70px;
}

html body .p-b-70px {
  padding-bottom: 70px;
}

html body .p-l-70px {
  padding-left: 70px;
}

html body .p-r-70px {
  padding-right: 70px;
}

html body .m-t-75px {
  margin-top: 75px;
}

html body .m-b-75px {
  margin-bottom: 75px;
}

html body .m-l-75px {
  margin-left: 75px;
}

html body .m-r-75px {
  margin-right: 75px;
}

html body .p-t-75px {
  padding-top: 75px;
}

html body .p-b-75px {
  padding-bottom: 75px;
}

html body .p-l-75px {
  padding-left: 75px;
}

html body .p-r-75px {
  padding-right: 75px;
}

html body .m-t-80px {
  margin-top: 80px;
}

html body .m-b-80px {
  margin-bottom: 80px;
}

html body .m-l-80px {
  margin-left: 80px;
}

html body .m-r-80px {
  margin-right: 80px;
}

html body .p-t-80px {
  padding-top: 80px;
}

html body .p-b-80px {
  padding-bottom: 80px;
}

html body .p-l-80px {
  padding-left: 80px;
}

html body .p-r-80px {
  padding-right: 80px;
}

html body .m-t-85px {
  margin-top: 85px;
}

html body .m-b-85px {
  margin-bottom: 85px;
}

html body .m-l-85px {
  margin-left: 85px;
}

html body .m-r-85px {
  margin-right: 85px;
}

html body .p-t-85px {
  padding-top: 85px;
}

html body .p-b-85px {
  padding-bottom: 85px;
}

html body .p-l-85px {
  padding-left: 85px;
}

html body .p-r-85px {
  padding-right: 85px;
}

html body .m-t-90px {
  margin-top: 90px;
}

html body .m-b-90px {
  margin-bottom: 90px;
}

html body .m-l-90px {
  margin-left: 90px;
}

html body .m-r-90px {
  margin-right: 90px;
}

html body .p-t-90px {
  padding-top: 90px;
}

html body .p-b-90px {
  padding-bottom: 90px;
}

html body .p-l-90px {
  padding-left: 90px;
}

html body .p-r-90px {
  padding-right: 90px;
}

html body .m-t-95px {
  margin-top: 95px;
}

html body .m-b-95px {
  margin-bottom: 95px;
}

html body .m-l-95px {
  margin-left: 95px;
}

html body .m-r-95px {
  margin-right: 95px;
}

html body .p-t-95px {
  padding-top: 95px;
}

html body .p-b-95px {
  padding-bottom: 95px;
}

html body .p-l-95px {
  padding-left: 95px;
}

html body .p-r-95px {
  padding-right: 95px;
}

html body .m-t-100px {
  margin-top: 100px;
}

html body .m-b-100px {
  margin-bottom: 100px;
}

html body .m-l-100px {
  margin-left: 100px;
}

html body .m-r-100px {
  margin-right: 100px;
}

html body .p-t-100px {
  padding-top: 100px;
}

html body .p-b-100px {
  padding-bottom: 100px;
}

html body .p-l-100px {
  padding-left: 100px;
}

html body .p-r-100px {
  padding-right: 100px;
}

html body .m-t-105px {
  margin-top: 105px;
}

html body .m-b-105px {
  margin-bottom: 105px;
}

html body .m-l-105px {
  margin-left: 105px;
}

html body .m-r-105px {
  margin-right: 105px;
}

html body .p-t-105px {
  padding-top: 105px;
}

html body .p-b-105px {
  padding-bottom: 105px;
}

html body .p-l-105px {
  padding-left: 105px;
}

html body .p-r-105px {
  padding-right: 105px;
}

html body .m-t-110px {
  margin-top: 110px;
}

html body .m-b-110px {
  margin-bottom: 110px;
}

html body .m-l-110px {
  margin-left: 110px;
}

html body .m-r-110px {
  margin-right: 110px;
}

html body .p-t-110px {
  padding-top: 110px;
}

html body .p-b-110px {
  padding-bottom: 110px;
}

html body .p-l-110px {
  padding-left: 110px;
}

html body .p-r-110px {
  padding-right: 110px;
}

html body .m-t-115px {
  margin-top: 115px;
}

html body .m-b-115px {
  margin-bottom: 115px;
}

html body .m-l-115px {
  margin-left: 115px;
}

html body .m-r-115px {
  margin-right: 115px;
}

html body .p-t-115px {
  padding-top: 115px;
}

html body .p-b-115px {
  padding-bottom: 115px;
}

html body .p-l-115px {
  padding-left: 115px;
}

html body .p-r-115px {
  padding-right: 115px;
}

html body .m-t-120px {
  margin-top: 120px;
}

html body .m-b-120px {
  margin-bottom: 120px;
}

html body .m-l-120px {
  margin-left: 120px;
}

html body .m-r-120px {
  margin-right: 120px;
}

html body .p-t-120px {
  padding-top: 120px;
}

html body .p-b-120px {
  padding-bottom: 120px;
}

html body .p-l-120px {
  padding-left: 120px;
}

html body .p-r-120px {
  padding-right: 120px;
}

html body .m-t-125px {
  margin-top: 125px;
}

html body .m-b-125px {
  margin-bottom: 125px;
}

html body .m-l-125px {
  margin-left: 125px;
}

html body .m-r-125px {
  margin-right: 125px;
}

html body .p-t-125px {
  padding-top: 125px;
}

html body .p-b-125px {
  padding-bottom: 125px;
}

html body .p-l-125px {
  padding-left: 125px;
}

html body .p-r-125px {
  padding-right: 125px;
}

html body .m-t-130px {
  margin-top: 130px;
}

html body .m-b-130px {
  margin-bottom: 130px;
}

html body .m-l-130px {
  margin-left: 130px;
}

html body .m-r-130px {
  margin-right: 130px;
}

html body .p-t-130px {
  padding-top: 130px;
}

html body .p-b-130px {
  padding-bottom: 130px;
}

html body .p-l-130px {
  padding-left: 130px;
}

html body .p-r-130px {
  padding-right: 130px;
}

html body .m-t-135px {
  margin-top: 135px;
}

html body .m-b-135px {
  margin-bottom: 135px;
}

html body .m-l-135px {
  margin-left: 135px;
}

html body .m-r-135px {
  margin-right: 135px;
}

html body .p-t-135px {
  padding-top: 135px;
}

html body .p-b-135px {
  padding-bottom: 135px;
}

html body .p-l-135px {
  padding-left: 135px;
}

html body .p-r-135px {
  padding-right: 135px;
}

html body .m-t-140px {
  margin-top: 140px;
}

html body .m-b-140px {
  margin-bottom: 140px;
}

html body .m-l-140px {
  margin-left: 140px;
}

html body .m-r-140px {
  margin-right: 140px;
}

html body .p-t-140px {
  padding-top: 140px;
}

html body .p-b-140px {
  padding-bottom: 140px;
}

html body .p-l-140px {
  padding-left: 140px;
}

html body .p-r-140px {
  padding-right: 140px;
}

html body .m-t-145px {
  margin-top: 145px;
}

html body .m-b-145px {
  margin-bottom: 145px;
}

html body .m-l-145px {
  margin-left: 145px;
}

html body .m-r-145px {
  margin-right: 145px;
}

html body .p-t-145px {
  padding-top: 145px;
}

html body .p-b-145px {
  padding-bottom: 145px;
}

html body .p-l-145px {
  padding-left: 145px;
}

html body .p-r-145px {
  padding-right: 145px;
}

html body .m-t-150px {
  margin-top: 150px;
}

html body .m-b-150px {
  margin-bottom: 150px;
}

html body .m-l-150px {
  margin-left: 150px;
}

html body .m-r-150px {
  margin-right: 150px;
}

html body .p-t-150px {
  padding-top: 150px;
}

html body .p-b-150px {
  padding-bottom: 150px;
}

html body .p-l-150px {
  padding-left: 150px;
}

html body .p-r-150px {
  padding-right: 150px;
}

html body .m-t-155px {
  margin-top: 155px;
}

html body .m-b-155px {
  margin-bottom: 155px;
}

html body .m-l-155px {
  margin-left: 155px;
}

html body .m-r-155px {
  margin-right: 155px;
}

html body .p-t-155px {
  padding-top: 155px;
}

html body .p-b-155px {
  padding-bottom: 155px;
}

html body .p-l-155px {
  padding-left: 155px;
}

html body .p-r-155px {
  padding-right: 155px;
}

html body .m-t-160px {
  margin-top: 160px;
}

html body .m-b-160px {
  margin-bottom: 160px;
}

html body .m-l-160px {
  margin-left: 160px;
}

html body .m-r-160px {
  margin-right: 160px;
}

html body .p-t-160px {
  padding-top: 160px;
}

html body .p-b-160px {
  padding-bottom: 160px;
}

html body .p-l-160px {
  padding-left: 160px;
}

html body .p-r-160px {
  padding-right: 160px;
}

html body .m-t-165px {
  margin-top: 165px;
}

html body .m-b-165px {
  margin-bottom: 165px;
}

html body .m-l-165px {
  margin-left: 165px;
}

html body .m-r-165px {
  margin-right: 165px;
}

html body .p-t-165px {
  padding-top: 165px;
}

html body .p-b-165px {
  padding-bottom: 165px;
}

html body .p-l-165px {
  padding-left: 165px;
}

html body .p-r-165px {
  padding-right: 165px;
}

html body .m-t-170px {
  margin-top: 170px;
}

html body .m-b-170px {
  margin-bottom: 170px;
}

html body .m-l-170px {
  margin-left: 170px;
}

html body .m-r-170px {
  margin-right: 170px;
}

html body .p-t-170px {
  padding-top: 170px;
}

html body .p-b-170px {
  padding-bottom: 170px;
}

html body .p-l-170px {
  padding-left: 170px;
}

html body .p-r-170px {
  padding-right: 170px;
}

html body .m-t-175px {
  margin-top: 175px;
}

html body .m-b-175px {
  margin-bottom: 175px;
}

html body .m-l-175px {
  margin-left: 175px;
}

html body .m-r-175px {
  margin-right: 175px;
}

html body .p-t-175px {
  padding-top: 175px;
}

html body .p-b-175px {
  padding-bottom: 175px;
}

html body .p-l-175px {
  padding-left: 175px;
}

html body .p-r-175px {
  padding-right: 175px;
}

html body .m-t-180px {
  margin-top: 180px;
}

html body .m-b-180px {
  margin-bottom: 180px;
}

html body .m-l-180px {
  margin-left: 180px;
}

html body .m-r-180px {
  margin-right: 180px;
}

html body .p-t-180px {
  padding-top: 180px;
}

html body .p-b-180px {
  padding-bottom: 180px;
}

html body .p-l-180px {
  padding-left: 180px;
}

html body .p-r-180px {
  padding-right: 180px;
}

html body .m-t-185px {
  margin-top: 185px;
}

html body .m-b-185px {
  margin-bottom: 185px;
}

html body .m-l-185px {
  margin-left: 185px;
}

html body .m-r-185px {
  margin-right: 185px;
}

html body .p-t-185px {
  padding-top: 185px;
}

html body .p-b-185px {
  padding-bottom: 185px;
}

html body .p-l-185px {
  padding-left: 185px;
}

html body .p-r-185px {
  padding-right: 185px;
}

html body .m-t-190px {
  margin-top: 190px;
}

html body .m-b-190px {
  margin-bottom: 190px;
}

html body .m-l-190px {
  margin-left: 190px;
}

html body .m-r-190px {
  margin-right: 190px;
}

html body .p-t-190px {
  padding-top: 190px;
}

html body .p-b-190px {
  padding-bottom: 190px;
}

html body .p-l-190px {
  padding-left: 190px;
}

html body .p-r-190px {
  padding-right: 190px;
}

html body .m-t-195px {
  margin-top: 195px;
}

html body .m-b-195px {
  margin-bottom: 195px;
}

html body .m-l-195px {
  margin-left: 195px;
}

html body .m-r-195px {
  margin-right: 195px;
}

html body .p-t-195px {
  padding-top: 195px;
}

html body .p-b-195px {
  padding-bottom: 195px;
}

html body .p-l-195px {
  padding-left: 195px;
}

html body .p-r-195px {
  padding-right: 195px;
}

html body .m-t-200px {
  margin-top: 200px;
}

html body .m-b-200px {
  margin-bottom: 200px;
}

html body .m-l-200px {
  margin-left: 200px;
}

html body .m-r-200px {
  margin-right: 200px;
}

html body .p-t-200px {
  padding-top: 200px;
}

html body .p-b-200px {
  padding-bottom: 200px;
}

html body .p-l-200px {
  padding-left: 200px;
}

html body .p-r-200px {
  padding-right: 200px;
}

html body .m-t-205px {
  margin-top: 205px;
}

html body .m-b-205px {
  margin-bottom: 205px;
}

html body .m-l-205px {
  margin-left: 205px;
}

html body .m-r-205px {
  margin-right: 205px;
}

html body .p-t-205px {
  padding-top: 205px;
}

html body .p-b-205px {
  padding-bottom: 205px;
}

html body .p-l-205px {
  padding-left: 205px;
}

html body .p-r-205px {
  padding-right: 205px;
}

html body .m-t-210px {
  margin-top: 210px;
}

html body .m-b-210px {
  margin-bottom: 210px;
}

html body .m-l-210px {
  margin-left: 210px;
}

html body .m-r-210px {
  margin-right: 210px;
}

html body .p-t-210px {
  padding-top: 210px;
}

html body .p-b-210px {
  padding-bottom: 210px;
}

html body .p-l-210px {
  padding-left: 210px;
}

html body .p-r-210px {
  padding-right: 210px;
}

html body .m-t-215px {
  margin-top: 215px;
}

html body .m-b-215px {
  margin-bottom: 215px;
}

html body .m-l-215px {
  margin-left: 215px;
}

html body .m-r-215px {
  margin-right: 215px;
}

html body .p-t-215px {
  padding-top: 215px;
}

html body .p-b-215px {
  padding-bottom: 215px;
}

html body .p-l-215px {
  padding-left: 215px;
}

html body .p-r-215px {
  padding-right: 215px;
}

html body .m-t-220px {
  margin-top: 220px;
}

html body .m-b-220px {
  margin-bottom: 220px;
}

html body .m-l-220px {
  margin-left: 220px;
}

html body .m-r-220px {
  margin-right: 220px;
}

html body .p-t-220px {
  padding-top: 220px;
}

html body .p-b-220px {
  padding-bottom: 220px;
}

html body .p-l-220px {
  padding-left: 220px;
}

html body .p-r-220px {
  padding-right: 220px;
}

html body .m-t-225px {
  margin-top: 225px;
}

html body .m-b-225px {
  margin-bottom: 225px;
}

html body .m-l-225px {
  margin-left: 225px;
}

html body .m-r-225px {
  margin-right: 225px;
}

html body .p-t-225px {
  padding-top: 225px;
}

html body .p-b-225px {
  padding-bottom: 225px;
}

html body .p-l-225px {
  padding-left: 225px;
}

html body .p-r-225px {
  padding-right: 225px;
}

html body .m-t-230px {
  margin-top: 230px;
}

html body .m-b-230px {
  margin-bottom: 230px;
}

html body .m-l-230px {
  margin-left: 230px;
}

html body .m-r-230px {
  margin-right: 230px;
}

html body .p-t-230px {
  padding-top: 230px;
}

html body .p-b-230px {
  padding-bottom: 230px;
}

html body .p-l-230px {
  padding-left: 230px;
}

html body .p-r-230px {
  padding-right: 230px;
}

html body .m-t-235px {
  margin-top: 235px;
}

html body .m-b-235px {
  margin-bottom: 235px;
}

html body .m-l-235px {
  margin-left: 235px;
}

html body .m-r-235px {
  margin-right: 235px;
}

html body .p-t-235px {
  padding-top: 235px;
}

html body .p-b-235px {
  padding-bottom: 235px;
}

html body .p-l-235px {
  padding-left: 235px;
}

html body .p-r-235px {
  padding-right: 235px;
}

html body .m-t-240px {
  margin-top: 240px;
}

html body .m-b-240px {
  margin-bottom: 240px;
}

html body .m-l-240px {
  margin-left: 240px;
}

html body .m-r-240px {
  margin-right: 240px;
}

html body .p-t-240px {
  padding-top: 240px;
}

html body .p-b-240px {
  padding-bottom: 240px;
}

html body .p-l-240px {
  padding-left: 240px;
}

html body .p-r-240px {
  padding-right: 240px;
}

html body .m-t-245px {
  margin-top: 245px;
}

html body .m-b-245px {
  margin-bottom: 245px;
}

html body .m-l-245px {
  margin-left: 245px;
}

html body .m-r-245px {
  margin-right: 245px;
}

html body .p-t-245px {
  padding-top: 245px;
}

html body .p-b-245px {
  padding-bottom: 245px;
}

html body .p-l-245px {
  padding-left: 245px;
}

html body .p-r-245px {
  padding-right: 245px;
}

html body .m-t-250px {
  margin-top: 250px;
}

html body .m-b-250px {
  margin-bottom: 250px;
}

html body .m-l-250px {
  margin-left: 250px;
}

html body .m-r-250px {
  margin-right: 250px;
}

html body .p-t-250px {
  padding-top: 250px;
}

html body .p-b-250px {
  padding-bottom: 250px;
}

html body .p-l-250px {
  padding-left: 250px;
}

html body .p-r-250px {
  padding-right: 250px;
}

html body .m-t-255px {
  margin-top: 255px;
}

html body .m-b-255px {
  margin-bottom: 255px;
}

html body .m-l-255px {
  margin-left: 255px;
}

html body .m-r-255px {
  margin-right: 255px;
}

html body .p-t-255px {
  padding-top: 255px;
}

html body .p-b-255px {
  padding-bottom: 255px;
}

html body .p-l-255px {
  padding-left: 255px;
}

html body .p-r-255px {
  padding-right: 255px;
}

html body .m-t-260px {
  margin-top: 260px;
}

html body .m-b-260px {
  margin-bottom: 260px;
}

html body .m-l-260px {
  margin-left: 260px;
}

html body .m-r-260px {
  margin-right: 260px;
}

html body .p-t-260px {
  padding-top: 260px;
}

html body .p-b-260px {
  padding-bottom: 260px;
}

html body .p-l-260px {
  padding-left: 260px;
}

html body .p-r-260px {
  padding-right: 260px;
}

html body .m-t-265px {
  margin-top: 265px;
}

html body .m-b-265px {
  margin-bottom: 265px;
}

html body .m-l-265px {
  margin-left: 265px;
}

html body .m-r-265px {
  margin-right: 265px;
}

html body .p-t-265px {
  padding-top: 265px;
}

html body .p-b-265px {
  padding-bottom: 265px;
}

html body .p-l-265px {
  padding-left: 265px;
}

html body .p-r-265px {
  padding-right: 265px;
}

html body .m-t-270px {
  margin-top: 270px;
}

html body .m-b-270px {
  margin-bottom: 270px;
}

html body .m-l-270px {
  margin-left: 270px;
}

html body .m-r-270px {
  margin-right: 270px;
}

html body .p-t-270px {
  padding-top: 270px;
}

html body .p-b-270px {
  padding-bottom: 270px;
}

html body .p-l-270px {
  padding-left: 270px;
}

html body .p-r-270px {
  padding-right: 270px;
}

html body .m-t-275px {
  margin-top: 275px;
}

html body .m-b-275px {
  margin-bottom: 275px;
}

html body .m-l-275px {
  margin-left: 275px;
}

html body .m-r-275px {
  margin-right: 275px;
}

html body .p-t-275px {
  padding-top: 275px;
}

html body .p-b-275px {
  padding-bottom: 275px;
}

html body .p-l-275px {
  padding-left: 275px;
}

html body .p-r-275px {
  padding-right: 275px;
}

html body .m-t-280px {
  margin-top: 280px;
}

html body .m-b-280px {
  margin-bottom: 280px;
}

html body .m-l-280px {
  margin-left: 280px;
}

html body .m-r-280px {
  margin-right: 280px;
}

html body .p-t-280px {
  padding-top: 280px;
}

html body .p-b-280px {
  padding-bottom: 280px;
}

html body .p-l-280px {
  padding-left: 280px;
}

html body .p-r-280px {
  padding-right: 280px;
}

html body .m-t-285px {
  margin-top: 285px;
}

html body .m-b-285px {
  margin-bottom: 285px;
}

html body .m-l-285px {
  margin-left: 285px;
}

html body .m-r-285px {
  margin-right: 285px;
}

html body .p-t-285px {
  padding-top: 285px;
}

html body .p-b-285px {
  padding-bottom: 285px;
}

html body .p-l-285px {
  padding-left: 285px;
}

html body .p-r-285px {
  padding-right: 285px;
}

html body .m-t-290px {
  margin-top: 290px;
}

html body .m-b-290px {
  margin-bottom: 290px;
}

html body .m-l-290px {
  margin-left: 290px;
}

html body .m-r-290px {
  margin-right: 290px;
}

html body .p-t-290px {
  padding-top: 290px;
}

html body .p-b-290px {
  padding-bottom: 290px;
}

html body .p-l-290px {
  padding-left: 290px;
}

html body .p-r-290px {
  padding-right: 290px;
}

html body .m-t-295px {
  margin-top: 295px;
}

html body .m-b-295px {
  margin-bottom: 295px;
}

html body .m-l-295px {
  margin-left: 295px;
}

html body .m-r-295px {
  margin-right: 295px;
}

html body .p-t-295px {
  padding-top: 295px;
}

html body .p-b-295px {
  padding-bottom: 295px;
}

html body .p-l-295px {
  padding-left: 295px;
}

html body .p-r-295px {
  padding-right: 295px;
}

html body .m-t-300px {
  margin-top: 300px;
}

html body .m-b-300px {
  margin-bottom: 300px;
}

html body .m-l-300px {
  margin-left: 300px;
}

html body .m-r-300px {
  margin-right: 300px;
}

html body .p-t-300px {
  padding-top: 300px;
}

html body .p-b-300px {
  padding-bottom: 300px;
}

html body .p-l-300px {
  padding-left: 300px;
}

html body .p-r-300px {
  padding-right: 300px;
}

html body .m-t-305px {
  margin-top: 305px;
}

html body .m-b-305px {
  margin-bottom: 305px;
}

html body .m-l-305px {
  margin-left: 305px;
}

html body .m-r-305px {
  margin-right: 305px;
}

html body .p-t-305px {
  padding-top: 305px;
}

html body .p-b-305px {
  padding-bottom: 305px;
}

html body .p-l-305px {
  padding-left: 305px;
}

html body .p-r-305px {
  padding-right: 305px;
}

html body .m-t-310px {
  margin-top: 310px;
}

html body .m-b-310px {
  margin-bottom: 310px;
}

html body .m-l-310px {
  margin-left: 310px;
}

html body .m-r-310px {
  margin-right: 310px;
}

html body .p-t-310px {
  padding-top: 310px;
}

html body .p-b-310px {
  padding-bottom: 310px;
}

html body .p-l-310px {
  padding-left: 310px;
}

html body .p-r-310px {
  padding-right: 310px;
}

html body .m-t-315px {
  margin-top: 315px;
}

html body .m-b-315px {
  margin-bottom: 315px;
}

html body .m-l-315px {
  margin-left: 315px;
}

html body .m-r-315px {
  margin-right: 315px;
}

html body .p-t-315px {
  padding-top: 315px;
}

html body .p-b-315px {
  padding-bottom: 315px;
}

html body .p-l-315px {
  padding-left: 315px;
}

html body .p-r-315px {
  padding-right: 315px;
}

html body .m-t-320px {
  margin-top: 320px;
}

html body .m-b-320px {
  margin-bottom: 320px;
}

html body .m-l-320px {
  margin-left: 320px;
}

html body .m-r-320px {
  margin-right: 320px;
}

html body .p-t-320px {
  padding-top: 320px;
}

html body .p-b-320px {
  padding-bottom: 320px;
}

html body .p-l-320px {
  padding-left: 320px;
}

html body .p-r-320px {
  padding-right: 320px;
}

html body .m-t-325px {
  margin-top: 325px;
}

html body .m-b-325px {
  margin-bottom: 325px;
}

html body .m-l-325px {
  margin-left: 325px;
}

html body .m-r-325px {
  margin-right: 325px;
}

html body .p-t-325px {
  padding-top: 325px;
}

html body .p-b-325px {
  padding-bottom: 325px;
}

html body .p-l-325px {
  padding-left: 325px;
}

html body .p-r-325px {
  padding-right: 325px;
}

html body .m-t-330px {
  margin-top: 330px;
}

html body .m-b-330px {
  margin-bottom: 330px;
}

html body .m-l-330px {
  margin-left: 330px;
}

html body .m-r-330px {
  margin-right: 330px;
}

html body .p-t-330px {
  padding-top: 330px;
}

html body .p-b-330px {
  padding-bottom: 330px;
}

html body .p-l-330px {
  padding-left: 330px;
}

html body .p-r-330px {
  padding-right: 330px;
}

html body .m-t-335px {
  margin-top: 335px;
}

html body .m-b-335px {
  margin-bottom: 335px;
}

html body .m-l-335px {
  margin-left: 335px;
}

html body .m-r-335px {
  margin-right: 335px;
}

html body .p-t-335px {
  padding-top: 335px;
}

html body .p-b-335px {
  padding-bottom: 335px;
}

html body .p-l-335px {
  padding-left: 335px;
}

html body .p-r-335px {
  padding-right: 335px;
}

html body .m-t-340px {
  margin-top: 340px;
}

html body .m-b-340px {
  margin-bottom: 340px;
}

html body .m-l-340px {
  margin-left: 340px;
}

html body .m-r-340px {
  margin-right: 340px;
}

html body .p-t-340px {
  padding-top: 340px;
}

html body .p-b-340px {
  padding-bottom: 340px;
}

html body .p-l-340px {
  padding-left: 340px;
}

html body .p-r-340px {
  padding-right: 340px;
}

html body .m-t-345px {
  margin-top: 345px;
}

html body .m-b-345px {
  margin-bottom: 345px;
}

html body .m-l-345px {
  margin-left: 345px;
}

html body .m-r-345px {
  margin-right: 345px;
}

html body .p-t-345px {
  padding-top: 345px;
}

html body .p-b-345px {
  padding-bottom: 345px;
}

html body .p-l-345px {
  padding-left: 345px;
}

html body .p-r-345px {
  padding-right: 345px;
}

html body .m-t-350px {
  margin-top: 350px;
}

html body .m-b-350px {
  margin-bottom: 350px;
}

html body .m-l-350px {
  margin-left: 350px;
}

html body .m-r-350px {
  margin-right: 350px;
}

html body .p-t-350px {
  padding-top: 350px;
}

html body .p-b-350px {
  padding-bottom: 350px;
}

html body .p-l-350px {
  padding-left: 350px;
}

html body .p-r-350px {
  padding-right: 350px;
}

html body .m-t-355px {
  margin-top: 355px;
}

html body .m-b-355px {
  margin-bottom: 355px;
}

html body .m-l-355px {
  margin-left: 355px;
}

html body .m-r-355px {
  margin-right: 355px;
}

html body .p-t-355px {
  padding-top: 355px;
}

html body .p-b-355px {
  padding-bottom: 355px;
}

html body .p-l-355px {
  padding-left: 355px;
}

html body .p-r-355px {
  padding-right: 355px;
}

html body .m-t-360px {
  margin-top: 360px;
}

html body .m-b-360px {
  margin-bottom: 360px;
}

html body .m-l-360px {
  margin-left: 360px;
}

html body .m-r-360px {
  margin-right: 360px;
}

html body .p-t-360px {
  padding-top: 360px;
}

html body .p-b-360px {
  padding-bottom: 360px;
}

html body .p-l-360px {
  padding-left: 360px;
}

html body .p-r-360px {
  padding-right: 360px;
}

html body .m-t-365px {
  margin-top: 365px;
}

html body .m-b-365px {
  margin-bottom: 365px;
}

html body .m-l-365px {
  margin-left: 365px;
}

html body .m-r-365px {
  margin-right: 365px;
}

html body .p-t-365px {
  padding-top: 365px;
}

html body .p-b-365px {
  padding-bottom: 365px;
}

html body .p-l-365px {
  padding-left: 365px;
}

html body .p-r-365px {
  padding-right: 365px;
}

html body .m-t-370px {
  margin-top: 370px;
}

html body .m-b-370px {
  margin-bottom: 370px;
}

html body .m-l-370px {
  margin-left: 370px;
}

html body .m-r-370px {
  margin-right: 370px;
}

html body .p-t-370px {
  padding-top: 370px;
}

html body .p-b-370px {
  padding-bottom: 370px;
}

html body .p-l-370px {
  padding-left: 370px;
}

html body .p-r-370px {
  padding-right: 370px;
}

html body .m-t-375px {
  margin-top: 375px;
}

html body .m-b-375px {
  margin-bottom: 375px;
}

html body .m-l-375px {
  margin-left: 375px;
}

html body .m-r-375px {
  margin-right: 375px;
}

html body .p-t-375px {
  padding-top: 375px;
}

html body .p-b-375px {
  padding-bottom: 375px;
}

html body .p-l-375px {
  padding-left: 375px;
}

html body .p-r-375px {
  padding-right: 375px;
}

html body .m-t-380px {
  margin-top: 380px;
}

html body .m-b-380px {
  margin-bottom: 380px;
}

html body .m-l-380px {
  margin-left: 380px;
}

html body .m-r-380px {
  margin-right: 380px;
}

html body .p-t-380px {
  padding-top: 380px;
}

html body .p-b-380px {
  padding-bottom: 380px;
}

html body .p-l-380px {
  padding-left: 380px;
}

html body .p-r-380px {
  padding-right: 380px;
}

html body .m-t-385px {
  margin-top: 385px;
}

html body .m-b-385px {
  margin-bottom: 385px;
}

html body .m-l-385px {
  margin-left: 385px;
}

html body .m-r-385px {
  margin-right: 385px;
}

html body .p-t-385px {
  padding-top: 385px;
}

html body .p-b-385px {
  padding-bottom: 385px;
}

html body .p-l-385px {
  padding-left: 385px;
}

html body .p-r-385px {
  padding-right: 385px;
}

html body .m-t-390px {
  margin-top: 390px;
}

html body .m-b-390px {
  margin-bottom: 390px;
}

html body .m-l-390px {
  margin-left: 390px;
}

html body .m-r-390px {
  margin-right: 390px;
}

html body .p-t-390px {
  padding-top: 390px;
}

html body .p-b-390px {
  padding-bottom: 390px;
}

html body .p-l-390px {
  padding-left: 390px;
}

html body .p-r-390px {
  padding-right: 390px;
}

html body .m-t-395px {
  margin-top: 395px;
}

html body .m-b-395px {
  margin-bottom: 395px;
}

html body .m-l-395px {
  margin-left: 395px;
}

html body .m-r-395px {
  margin-right: 395px;
}

html body .p-t-395px {
  padding-top: 395px;
}

html body .p-b-395px {
  padding-bottom: 395px;
}

html body .p-l-395px {
  padding-left: 395px;
}

html body .p-r-395px {
  padding-right: 395px;
}

html body .m-t-400px {
  margin-top: 400px;
}

html body .m-b-400px {
  margin-bottom: 400px;
}

html body .m-l-400px {
  margin-left: 400px;
}

html body .m-r-400px {
  margin-right: 400px;
}

html body .p-t-400px {
  padding-top: 400px;
}

html body .p-b-400px {
  padding-bottom: 400px;
}

html body .p-l-400px {
  padding-left: 400px;
}

html body .p-r-400px {
  padding-right: 400px;
}

html body .m-t-405px {
  margin-top: 405px;
}

html body .m-b-405px {
  margin-bottom: 405px;
}

html body .m-l-405px {
  margin-left: 405px;
}

html body .m-r-405px {
  margin-right: 405px;
}

html body .p-t-405px {
  padding-top: 405px;
}

html body .p-b-405px {
  padding-bottom: 405px;
}

html body .p-l-405px {
  padding-left: 405px;
}

html body .p-r-405px {
  padding-right: 405px;
}

html body .m-t-410px {
  margin-top: 410px;
}

html body .m-b-410px {
  margin-bottom: 410px;
}

html body .m-l-410px {
  margin-left: 410px;
}

html body .m-r-410px {
  margin-right: 410px;
}

html body .p-t-410px {
  padding-top: 410px;
}

html body .p-b-410px {
  padding-bottom: 410px;
}

html body .p-l-410px {
  padding-left: 410px;
}

html body .p-r-410px {
  padding-right: 410px;
}

html body .m-t-415px {
  margin-top: 415px;
}

html body .m-b-415px {
  margin-bottom: 415px;
}

html body .m-l-415px {
  margin-left: 415px;
}

html body .m-r-415px {
  margin-right: 415px;
}

html body .p-t-415px {
  padding-top: 415px;
}

html body .p-b-415px {
  padding-bottom: 415px;
}

html body .p-l-415px {
  padding-left: 415px;
}

html body .p-r-415px {
  padding-right: 415px;
}

html body .m-t-420px {
  margin-top: 420px;
}

html body .m-b-420px {
  margin-bottom: 420px;
}

html body .m-l-420px {
  margin-left: 420px;
}

html body .m-r-420px {
  margin-right: 420px;
}

html body .p-t-420px {
  padding-top: 420px;
}

html body .p-b-420px {
  padding-bottom: 420px;
}

html body .p-l-420px {
  padding-left: 420px;
}

html body .p-r-420px {
  padding-right: 420px;
}

html body .m-t-425px {
  margin-top: 425px;
}

html body .m-b-425px {
  margin-bottom: 425px;
}

html body .m-l-425px {
  margin-left: 425px;
}

html body .m-r-425px {
  margin-right: 425px;
}

html body .p-t-425px {
  padding-top: 425px;
}

html body .p-b-425px {
  padding-bottom: 425px;
}

html body .p-l-425px {
  padding-left: 425px;
}

html body .p-r-425px {
  padding-right: 425px;
}

html body .m-t-430px {
  margin-top: 430px;
}

html body .m-b-430px {
  margin-bottom: 430px;
}

html body .m-l-430px {
  margin-left: 430px;
}

html body .m-r-430px {
  margin-right: 430px;
}

html body .p-t-430px {
  padding-top: 430px;
}

html body .p-b-430px {
  padding-bottom: 430px;
}

html body .p-l-430px {
  padding-left: 430px;
}

html body .p-r-430px {
  padding-right: 430px;
}

html body .m-t-435px {
  margin-top: 435px;
}

html body .m-b-435px {
  margin-bottom: 435px;
}

html body .m-l-435px {
  margin-left: 435px;
}

html body .m-r-435px {
  margin-right: 435px;
}

html body .p-t-435px {
  padding-top: 435px;
}

html body .p-b-435px {
  padding-bottom: 435px;
}

html body .p-l-435px {
  padding-left: 435px;
}

html body .p-r-435px {
  padding-right: 435px;
}

html body .m-t-440px {
  margin-top: 440px;
}

html body .m-b-440px {
  margin-bottom: 440px;
}

html body .m-l-440px {
  margin-left: 440px;
}

html body .m-r-440px {
  margin-right: 440px;
}

html body .p-t-440px {
  padding-top: 440px;
}

html body .p-b-440px {
  padding-bottom: 440px;
}

html body .p-l-440px {
  padding-left: 440px;
}

html body .p-r-440px {
  padding-right: 440px;
}

html body .m-t-445px {
  margin-top: 445px;
}

html body .m-b-445px {
  margin-bottom: 445px;
}

html body .m-l-445px {
  margin-left: 445px;
}

html body .m-r-445px {
  margin-right: 445px;
}

html body .p-t-445px {
  padding-top: 445px;
}

html body .p-b-445px {
  padding-bottom: 445px;
}

html body .p-l-445px {
  padding-left: 445px;
}

html body .p-r-445px {
  padding-right: 445px;
}

html body .m-t-450px {
  margin-top: 450px;
}

html body .m-b-450px {
  margin-bottom: 450px;
}

html body .m-l-450px {
  margin-left: 450px;
}

html body .m-r-450px {
  margin-right: 450px;
}

html body .p-t-450px {
  padding-top: 450px;
}

html body .p-b-450px {
  padding-bottom: 450px;
}

html body .p-l-450px {
  padding-left: 450px;
}

html body .p-r-450px {
  padding-right: 450px;
}

html body .m-t-455px {
  margin-top: 455px;
}

html body .m-b-455px {
  margin-bottom: 455px;
}

html body .m-l-455px {
  margin-left: 455px;
}

html body .m-r-455px {
  margin-right: 455px;
}

html body .p-t-455px {
  padding-top: 455px;
}

html body .p-b-455px {
  padding-bottom: 455px;
}

html body .p-l-455px {
  padding-left: 455px;
}

html body .p-r-455px {
  padding-right: 455px;
}

html body .m-t-460px {
  margin-top: 460px;
}

html body .m-b-460px {
  margin-bottom: 460px;
}

html body .m-l-460px {
  margin-left: 460px;
}

html body .m-r-460px {
  margin-right: 460px;
}

html body .p-t-460px {
  padding-top: 460px;
}

html body .p-b-460px {
  padding-bottom: 460px;
}

html body .p-l-460px {
  padding-left: 460px;
}

html body .p-r-460px {
  padding-right: 460px;
}

html body .m-t-465px {
  margin-top: 465px;
}

html body .m-b-465px {
  margin-bottom: 465px;
}

html body .m-l-465px {
  margin-left: 465px;
}

html body .m-r-465px {
  margin-right: 465px;
}

html body .p-t-465px {
  padding-top: 465px;
}

html body .p-b-465px {
  padding-bottom: 465px;
}

html body .p-l-465px {
  padding-left: 465px;
}

html body .p-r-465px {
  padding-right: 465px;
}

html body .m-t-470px {
  margin-top: 470px;
}

html body .m-b-470px {
  margin-bottom: 470px;
}

html body .m-l-470px {
  margin-left: 470px;
}

html body .m-r-470px {
  margin-right: 470px;
}

html body .p-t-470px {
  padding-top: 470px;
}

html body .p-b-470px {
  padding-bottom: 470px;
}

html body .p-l-470px {
  padding-left: 470px;
}

html body .p-r-470px {
  padding-right: 470px;
}

html body .m-t-475px {
  margin-top: 475px;
}

html body .m-b-475px {
  margin-bottom: 475px;
}

html body .m-l-475px {
  margin-left: 475px;
}

html body .m-r-475px {
  margin-right: 475px;
}

html body .p-t-475px {
  padding-top: 475px;
}

html body .p-b-475px {
  padding-bottom: 475px;
}

html body .p-l-475px {
  padding-left: 475px;
}

html body .p-r-475px {
  padding-right: 475px;
}

html body .m-t-480px {
  margin-top: 480px;
}

html body .m-b-480px {
  margin-bottom: 480px;
}

html body .m-l-480px {
  margin-left: 480px;
}

html body .m-r-480px {
  margin-right: 480px;
}

html body .p-t-480px {
  padding-top: 480px;
}

html body .p-b-480px {
  padding-bottom: 480px;
}

html body .p-l-480px {
  padding-left: 480px;
}

html body .p-r-480px {
  padding-right: 480px;
}

html body .m-t-485px {
  margin-top: 485px;
}

html body .m-b-485px {
  margin-bottom: 485px;
}

html body .m-l-485px {
  margin-left: 485px;
}

html body .m-r-485px {
  margin-right: 485px;
}

html body .p-t-485px {
  padding-top: 485px;
}

html body .p-b-485px {
  padding-bottom: 485px;
}

html body .p-l-485px {
  padding-left: 485px;
}

html body .p-r-485px {
  padding-right: 485px;
}

html body .m-t-490px {
  margin-top: 490px;
}

html body .m-b-490px {
  margin-bottom: 490px;
}

html body .m-l-490px {
  margin-left: 490px;
}

html body .m-r-490px {
  margin-right: 490px;
}

html body .p-t-490px {
  padding-top: 490px;
}

html body .p-b-490px {
  padding-bottom: 490px;
}

html body .p-l-490px {
  padding-left: 490px;
}

html body .p-r-490px {
  padding-right: 490px;
}

html body .m-t-495px {
  margin-top: 495px;
}

html body .m-b-495px {
  margin-bottom: 495px;
}

html body .m-l-495px {
  margin-left: 495px;
}

html body .m-r-495px {
  margin-right: 495px;
}

html body .p-t-495px {
  padding-top: 495px;
}

html body .p-b-495px {
  padding-bottom: 495px;
}

html body .p-l-495px {
  padding-left: 495px;
}

html body .p-r-495px {
  padding-right: 495px;
}

html body .m-t-500px {
  margin-top: 500px;
}

html body .m-b-500px {
  margin-bottom: 500px;
}

html body .m-l-500px {
  margin-left: 500px;
}

html body .m-r-500px {
  margin-right: 500px;
}

html body .p-t-500px {
  padding-top: 500px;
}

html body .p-b-500px {
  padding-bottom: 500px;
}

html body .p-l-500px {
  padding-left: 500px;
}

html body .p-r-500px {
  padding-right: 500px;
}

html body .m-t-505px {
  margin-top: 505px;
}

html body .m-b-505px {
  margin-bottom: 505px;
}

html body .m-l-505px {
  margin-left: 505px;
}

html body .m-r-505px {
  margin-right: 505px;
}

html body .p-t-505px {
  padding-top: 505px;
}

html body .p-b-505px {
  padding-bottom: 505px;
}

html body .p-l-505px {
  padding-left: 505px;
}

html body .p-r-505px {
  padding-right: 505px;
}

html body .m-t-510px {
  margin-top: 510px;
}

html body .m-b-510px {
  margin-bottom: 510px;
}

html body .m-l-510px {
  margin-left: 510px;
}

html body .m-r-510px {
  margin-right: 510px;
}

html body .p-t-510px {
  padding-top: 510px;
}

html body .p-b-510px {
  padding-bottom: 510px;
}

html body .p-l-510px {
  padding-left: 510px;
}

html body .p-r-510px {
  padding-right: 510px;
}

html body .m-t-515px {
  margin-top: 515px;
}

html body .m-b-515px {
  margin-bottom: 515px;
}

html body .m-l-515px {
  margin-left: 515px;
}

html body .m-r-515px {
  margin-right: 515px;
}

html body .p-t-515px {
  padding-top: 515px;
}

html body .p-b-515px {
  padding-bottom: 515px;
}

html body .p-l-515px {
  padding-left: 515px;
}

html body .p-r-515px {
  padding-right: 515px;
}

html body .m-t-520px {
  margin-top: 520px;
}

html body .m-b-520px {
  margin-bottom: 520px;
}

html body .m-l-520px {
  margin-left: 520px;
}

html body .m-r-520px {
  margin-right: 520px;
}

html body .p-t-520px {
  padding-top: 520px;
}

html body .p-b-520px {
  padding-bottom: 520px;
}

html body .p-l-520px {
  padding-left: 520px;
}

html body .p-r-520px {
  padding-right: 520px;
}

html body .m-t-525px {
  margin-top: 525px;
}

html body .m-b-525px {
  margin-bottom: 525px;
}

html body .m-l-525px {
  margin-left: 525px;
}

html body .m-r-525px {
  margin-right: 525px;
}

html body .p-t-525px {
  padding-top: 525px;
}

html body .p-b-525px {
  padding-bottom: 525px;
}

html body .p-l-525px {
  padding-left: 525px;
}

html body .p-r-525px {
  padding-right: 525px;
}

html body .m-t-530px {
  margin-top: 530px;
}

html body .m-b-530px {
  margin-bottom: 530px;
}

html body .m-l-530px {
  margin-left: 530px;
}

html body .m-r-530px {
  margin-right: 530px;
}

html body .p-t-530px {
  padding-top: 530px;
}

html body .p-b-530px {
  padding-bottom: 530px;
}

html body .p-l-530px {
  padding-left: 530px;
}

html body .p-r-530px {
  padding-right: 530px;
}

html body .m-t-535px {
  margin-top: 535px;
}

html body .m-b-535px {
  margin-bottom: 535px;
}

html body .m-l-535px {
  margin-left: 535px;
}

html body .m-r-535px {
  margin-right: 535px;
}

html body .p-t-535px {
  padding-top: 535px;
}

html body .p-b-535px {
  padding-bottom: 535px;
}

html body .p-l-535px {
  padding-left: 535px;
}

html body .p-r-535px {
  padding-right: 535px;
}

html body .m-t-540px {
  margin-top: 540px;
}

html body .m-b-540px {
  margin-bottom: 540px;
}

html body .m-l-540px {
  margin-left: 540px;
}

html body .m-r-540px {
  margin-right: 540px;
}

html body .p-t-540px {
  padding-top: 540px;
}

html body .p-b-540px {
  padding-bottom: 540px;
}

html body .p-l-540px {
  padding-left: 540px;
}

html body .p-r-540px {
  padding-right: 540px;
}

html body .m-t-545px {
  margin-top: 545px;
}

html body .m-b-545px {
  margin-bottom: 545px;
}

html body .m-l-545px {
  margin-left: 545px;
}

html body .m-r-545px {
  margin-right: 545px;
}

html body .p-t-545px {
  padding-top: 545px;
}

html body .p-b-545px {
  padding-bottom: 545px;
}

html body .p-l-545px {
  padding-left: 545px;
}

html body .p-r-545px {
  padding-right: 545px;
}

html body .m-t-550px {
  margin-top: 550px;
}

html body .m-b-550px {
  margin-bottom: 550px;
}

html body .m-l-550px {
  margin-left: 550px;
}

html body .m-r-550px {
  margin-right: 550px;
}

html body .p-t-550px {
  padding-top: 550px;
}

html body .p-b-550px {
  padding-bottom: 550px;
}

html body .p-l-550px {
  padding-left: 550px;
}

html body .p-r-550px {
  padding-right: 550px;
}

html body .m-t-555px {
  margin-top: 555px;
}

html body .m-b-555px {
  margin-bottom: 555px;
}

html body .m-l-555px {
  margin-left: 555px;
}

html body .m-r-555px {
  margin-right: 555px;
}

html body .p-t-555px {
  padding-top: 555px;
}

html body .p-b-555px {
  padding-bottom: 555px;
}

html body .p-l-555px {
  padding-left: 555px;
}

html body .p-r-555px {
  padding-right: 555px;
}

html body .m-t-560px {
  margin-top: 560px;
}

html body .m-b-560px {
  margin-bottom: 560px;
}

html body .m-l-560px {
  margin-left: 560px;
}

html body .m-r-560px {
  margin-right: 560px;
}

html body .p-t-560px {
  padding-top: 560px;
}

html body .p-b-560px {
  padding-bottom: 560px;
}

html body .p-l-560px {
  padding-left: 560px;
}

html body .p-r-560px {
  padding-right: 560px;
}

html body .m-t-565px {
  margin-top: 565px;
}

html body .m-b-565px {
  margin-bottom: 565px;
}

html body .m-l-565px {
  margin-left: 565px;
}

html body .m-r-565px {
  margin-right: 565px;
}

html body .p-t-565px {
  padding-top: 565px;
}

html body .p-b-565px {
  padding-bottom: 565px;
}

html body .p-l-565px {
  padding-left: 565px;
}

html body .p-r-565px {
  padding-right: 565px;
}

html body .m-t-570px {
  margin-top: 570px;
}

html body .m-b-570px {
  margin-bottom: 570px;
}

html body .m-l-570px {
  margin-left: 570px;
}

html body .m-r-570px {
  margin-right: 570px;
}

html body .p-t-570px {
  padding-top: 570px;
}

html body .p-b-570px {
  padding-bottom: 570px;
}

html body .p-l-570px {
  padding-left: 570px;
}

html body .p-r-570px {
  padding-right: 570px;
}

html body .m-t-575px {
  margin-top: 575px;
}

html body .m-b-575px {
  margin-bottom: 575px;
}

html body .m-l-575px {
  margin-left: 575px;
}

html body .m-r-575px {
  margin-right: 575px;
}

html body .p-t-575px {
  padding-top: 575px;
}

html body .p-b-575px {
  padding-bottom: 575px;
}

html body .p-l-575px {
  padding-left: 575px;
}

html body .p-r-575px {
  padding-right: 575px;
}

html body .m-t-580px {
  margin-top: 580px;
}

html body .m-b-580px {
  margin-bottom: 580px;
}

html body .m-l-580px {
  margin-left: 580px;
}

html body .m-r-580px {
  margin-right: 580px;
}

html body .p-t-580px {
  padding-top: 580px;
}

html body .p-b-580px {
  padding-bottom: 580px;
}

html body .p-l-580px {
  padding-left: 580px;
}

html body .p-r-580px {
  padding-right: 580px;
}

html body .m-t-585px {
  margin-top: 585px;
}

html body .m-b-585px {
  margin-bottom: 585px;
}

html body .m-l-585px {
  margin-left: 585px;
}

html body .m-r-585px {
  margin-right: 585px;
}

html body .p-t-585px {
  padding-top: 585px;
}

html body .p-b-585px {
  padding-bottom: 585px;
}

html body .p-l-585px {
  padding-left: 585px;
}

html body .p-r-585px {
  padding-right: 585px;
}

html body .m-t-590px {
  margin-top: 590px;
}

html body .m-b-590px {
  margin-bottom: 590px;
}

html body .m-l-590px {
  margin-left: 590px;
}

html body .m-r-590px {
  margin-right: 590px;
}

html body .p-t-590px {
  padding-top: 590px;
}

html body .p-b-590px {
  padding-bottom: 590px;
}

html body .p-l-590px {
  padding-left: 590px;
}

html body .p-r-590px {
  padding-right: 590px;
}

html body .m-t-595px {
  margin-top: 595px;
}

html body .m-b-595px {
  margin-bottom: 595px;
}

html body .m-l-595px {
  margin-left: 595px;
}

html body .m-r-595px {
  margin-right: 595px;
}

html body .p-t-595px {
  padding-top: 595px;
}

html body .p-b-595px {
  padding-bottom: 595px;
}

html body .p-l-595px {
  padding-left: 595px;
}

html body .p-r-595px {
  padding-right: 595px;
}

html body .m-t-600px {
  margin-top: 600px;
}

html body .m-b-600px {
  margin-bottom: 600px;
}

html body .m-l-600px {
  margin-left: 600px;
}

html body .m-r-600px {
  margin-right: 600px;
}

html body .p-t-600px {
  padding-top: 600px;
}

html body .p-b-600px {
  padding-bottom: 600px;
}

html body .p-l-600px {
  padding-left: 600px;
}

html body .p-r-600px {
  padding-right: 600px;
}

html body .m-t-605px {
  margin-top: 605px;
}

html body .m-b-605px {
  margin-bottom: 605px;
}

html body .m-l-605px {
  margin-left: 605px;
}

html body .m-r-605px {
  margin-right: 605px;
}

html body .p-t-605px {
  padding-top: 605px;
}

html body .p-b-605px {
  padding-bottom: 605px;
}

html body .p-l-605px {
  padding-left: 605px;
}

html body .p-r-605px {
  padding-right: 605px;
}

html body .m-t-610px {
  margin-top: 610px;
}

html body .m-b-610px {
  margin-bottom: 610px;
}

html body .m-l-610px {
  margin-left: 610px;
}

html body .m-r-610px {
  margin-right: 610px;
}

html body .p-t-610px {
  padding-top: 610px;
}

html body .p-b-610px {
  padding-bottom: 610px;
}

html body .p-l-610px {
  padding-left: 610px;
}

html body .p-r-610px {
  padding-right: 610px;
}

html body .m-t-615px {
  margin-top: 615px;
}

html body .m-b-615px {
  margin-bottom: 615px;
}

html body .m-l-615px {
  margin-left: 615px;
}

html body .m-r-615px {
  margin-right: 615px;
}

html body .p-t-615px {
  padding-top: 615px;
}

html body .p-b-615px {
  padding-bottom: 615px;
}

html body .p-l-615px {
  padding-left: 615px;
}

html body .p-r-615px {
  padding-right: 615px;
}

html body .m-t-620px {
  margin-top: 620px;
}

html body .m-b-620px {
  margin-bottom: 620px;
}

html body .m-l-620px {
  margin-left: 620px;
}

html body .m-r-620px {
  margin-right: 620px;
}

html body .p-t-620px {
  padding-top: 620px;
}

html body .p-b-620px {
  padding-bottom: 620px;
}

html body .p-l-620px {
  padding-left: 620px;
}

html body .p-r-620px {
  padding-right: 620px;
}

html body .m-t-625px {
  margin-top: 625px;
}

html body .m-b-625px {
  margin-bottom: 625px;
}

html body .m-l-625px {
  margin-left: 625px;
}

html body .m-r-625px {
  margin-right: 625px;
}

html body .p-t-625px {
  padding-top: 625px;
}

html body .p-b-625px {
  padding-bottom: 625px;
}

html body .p-l-625px {
  padding-left: 625px;
}

html body .p-r-625px {
  padding-right: 625px;
}

html body .m-t-630px {
  margin-top: 630px;
}

html body .m-b-630px {
  margin-bottom: 630px;
}

html body .m-l-630px {
  margin-left: 630px;
}

html body .m-r-630px {
  margin-right: 630px;
}

html body .p-t-630px {
  padding-top: 630px;
}

html body .p-b-630px {
  padding-bottom: 630px;
}

html body .p-l-630px {
  padding-left: 630px;
}

html body .p-r-630px {
  padding-right: 630px;
}

html body .m-t-635px {
  margin-top: 635px;
}

html body .m-b-635px {
  margin-bottom: 635px;
}

html body .m-l-635px {
  margin-left: 635px;
}

html body .m-r-635px {
  margin-right: 635px;
}

html body .p-t-635px {
  padding-top: 635px;
}

html body .p-b-635px {
  padding-bottom: 635px;
}

html body .p-l-635px {
  padding-left: 635px;
}

html body .p-r-635px {
  padding-right: 635px;
}

html body .m-t-640px {
  margin-top: 640px;
}

html body .m-b-640px {
  margin-bottom: 640px;
}

html body .m-l-640px {
  margin-left: 640px;
}

html body .m-r-640px {
  margin-right: 640px;
}

html body .p-t-640px {
  padding-top: 640px;
}

html body .p-b-640px {
  padding-bottom: 640px;
}

html body .p-l-640px {
  padding-left: 640px;
}

html body .p-r-640px {
  padding-right: 640px;
}

html body .m-t-645px {
  margin-top: 645px;
}

html body .m-b-645px {
  margin-bottom: 645px;
}

html body .m-l-645px {
  margin-left: 645px;
}

html body .m-r-645px {
  margin-right: 645px;
}

html body .p-t-645px {
  padding-top: 645px;
}

html body .p-b-645px {
  padding-bottom: 645px;
}

html body .p-l-645px {
  padding-left: 645px;
}

html body .p-r-645px {
  padding-right: 645px;
}

html body .m-t-650px {
  margin-top: 650px;
}

html body .m-b-650px {
  margin-bottom: 650px;
}

html body .m-l-650px {
  margin-left: 650px;
}

html body .m-r-650px {
  margin-right: 650px;
}

html body .p-t-650px {
  padding-top: 650px;
}

html body .p-b-650px {
  padding-bottom: 650px;
}

html body .p-l-650px {
  padding-left: 650px;
}

html body .p-r-650px {
  padding-right: 650px;
}

html body .m-t-655px {
  margin-top: 655px;
}

html body .m-b-655px {
  margin-bottom: 655px;
}

html body .m-l-655px {
  margin-left: 655px;
}

html body .m-r-655px {
  margin-right: 655px;
}

html body .p-t-655px {
  padding-top: 655px;
}

html body .p-b-655px {
  padding-bottom: 655px;
}

html body .p-l-655px {
  padding-left: 655px;
}

html body .p-r-655px {
  padding-right: 655px;
}

html body .m-t-660px {
  margin-top: 660px;
}

html body .m-b-660px {
  margin-bottom: 660px;
}

html body .m-l-660px {
  margin-left: 660px;
}

html body .m-r-660px {
  margin-right: 660px;
}

html body .p-t-660px {
  padding-top: 660px;
}

html body .p-b-660px {
  padding-bottom: 660px;
}

html body .p-l-660px {
  padding-left: 660px;
}

html body .p-r-660px {
  padding-right: 660px;
}

html body .m-t-665px {
  margin-top: 665px;
}

html body .m-b-665px {
  margin-bottom: 665px;
}

html body .m-l-665px {
  margin-left: 665px;
}

html body .m-r-665px {
  margin-right: 665px;
}

html body .p-t-665px {
  padding-top: 665px;
}

html body .p-b-665px {
  padding-bottom: 665px;
}

html body .p-l-665px {
  padding-left: 665px;
}

html body .p-r-665px {
  padding-right: 665px;
}

html body .m-t-670px {
  margin-top: 670px;
}

html body .m-b-670px {
  margin-bottom: 670px;
}

html body .m-l-670px {
  margin-left: 670px;
}

html body .m-r-670px {
  margin-right: 670px;
}

html body .p-t-670px {
  padding-top: 670px;
}

html body .p-b-670px {
  padding-bottom: 670px;
}

html body .p-l-670px {
  padding-left: 670px;
}

html body .p-r-670px {
  padding-right: 670px;
}

html body .m-t-675px {
  margin-top: 675px;
}

html body .m-b-675px {
  margin-bottom: 675px;
}

html body .m-l-675px {
  margin-left: 675px;
}

html body .m-r-675px {
  margin-right: 675px;
}

html body .p-t-675px {
  padding-top: 675px;
}

html body .p-b-675px {
  padding-bottom: 675px;
}

html body .p-l-675px {
  padding-left: 675px;
}

html body .p-r-675px {
  padding-right: 675px;
}

html body .m-t-680px {
  margin-top: 680px;
}

html body .m-b-680px {
  margin-bottom: 680px;
}

html body .m-l-680px {
  margin-left: 680px;
}

html body .m-r-680px {
  margin-right: 680px;
}

html body .p-t-680px {
  padding-top: 680px;
}

html body .p-b-680px {
  padding-bottom: 680px;
}

html body .p-l-680px {
  padding-left: 680px;
}

html body .p-r-680px {
  padding-right: 680px;
}

html body .m-t-685px {
  margin-top: 685px;
}

html body .m-b-685px {
  margin-bottom: 685px;
}

html body .m-l-685px {
  margin-left: 685px;
}

html body .m-r-685px {
  margin-right: 685px;
}

html body .p-t-685px {
  padding-top: 685px;
}

html body .p-b-685px {
  padding-bottom: 685px;
}

html body .p-l-685px {
  padding-left: 685px;
}

html body .p-r-685px {
  padding-right: 685px;
}

html body .m-t-690px {
  margin-top: 690px;
}

html body .m-b-690px {
  margin-bottom: 690px;
}

html body .m-l-690px {
  margin-left: 690px;
}

html body .m-r-690px {
  margin-right: 690px;
}

html body .p-t-690px {
  padding-top: 690px;
}

html body .p-b-690px {
  padding-bottom: 690px;
}

html body .p-l-690px {
  padding-left: 690px;
}

html body .p-r-690px {
  padding-right: 690px;
}

html body .m-t-695px {
  margin-top: 695px;
}

html body .m-b-695px {
  margin-bottom: 695px;
}

html body .m-l-695px {
  margin-left: 695px;
}

html body .m-r-695px {
  margin-right: 695px;
}

html body .p-t-695px {
  padding-top: 695px;
}

html body .p-b-695px {
  padding-bottom: 695px;
}

html body .p-l-695px {
  padding-left: 695px;
}

html body .p-r-695px {
  padding-right: 695px;
}

html body .m-t-700px {
  margin-top: 700px;
}

html body .m-b-700px {
  margin-bottom: 700px;
}

html body .m-l-700px {
  margin-left: 700px;
}

html body .m-r-700px {
  margin-right: 700px;
}

html body .p-t-700px {
  padding-top: 700px;
}

html body .p-b-700px {
  padding-bottom: 700px;
}

html body .p-l-700px {
  padding-left: 700px;
}

html body .p-r-700px {
  padding-right: 700px;
}

html body .m-t-705px {
  margin-top: 705px;
}

html body .m-b-705px {
  margin-bottom: 705px;
}

html body .m-l-705px {
  margin-left: 705px;
}

html body .m-r-705px {
  margin-right: 705px;
}

html body .p-t-705px {
  padding-top: 705px;
}

html body .p-b-705px {
  padding-bottom: 705px;
}

html body .p-l-705px {
  padding-left: 705px;
}

html body .p-r-705px {
  padding-right: 705px;
}

html body .m-t-710px {
  margin-top: 710px;
}

html body .m-b-710px {
  margin-bottom: 710px;
}

html body .m-l-710px {
  margin-left: 710px;
}

html body .m-r-710px {
  margin-right: 710px;
}

html body .p-t-710px {
  padding-top: 710px;
}

html body .p-b-710px {
  padding-bottom: 710px;
}

html body .p-l-710px {
  padding-left: 710px;
}

html body .p-r-710px {
  padding-right: 710px;
}

html body .m-t-715px {
  margin-top: 715px;
}

html body .m-b-715px {
  margin-bottom: 715px;
}

html body .m-l-715px {
  margin-left: 715px;
}

html body .m-r-715px {
  margin-right: 715px;
}

html body .p-t-715px {
  padding-top: 715px;
}

html body .p-b-715px {
  padding-bottom: 715px;
}

html body .p-l-715px {
  padding-left: 715px;
}

html body .p-r-715px {
  padding-right: 715px;
}

html body .m-t-720px {
  margin-top: 720px;
}

html body .m-b-720px {
  margin-bottom: 720px;
}

html body .m-l-720px {
  margin-left: 720px;
}

html body .m-r-720px {
  margin-right: 720px;
}

html body .p-t-720px {
  padding-top: 720px;
}

html body .p-b-720px {
  padding-bottom: 720px;
}

html body .p-l-720px {
  padding-left: 720px;
}

html body .p-r-720px {
  padding-right: 720px;
}

html body .m-t-725px {
  margin-top: 725px;
}

html body .m-b-725px {
  margin-bottom: 725px;
}

html body .m-l-725px {
  margin-left: 725px;
}

html body .m-r-725px {
  margin-right: 725px;
}

html body .p-t-725px {
  padding-top: 725px;
}

html body .p-b-725px {
  padding-bottom: 725px;
}

html body .p-l-725px {
  padding-left: 725px;
}

html body .p-r-725px {
  padding-right: 725px;
}

html body .m-t-730px {
  margin-top: 730px;
}

html body .m-b-730px {
  margin-bottom: 730px;
}

html body .m-l-730px {
  margin-left: 730px;
}

html body .m-r-730px {
  margin-right: 730px;
}

html body .p-t-730px {
  padding-top: 730px;
}

html body .p-b-730px {
  padding-bottom: 730px;
}

html body .p-l-730px {
  padding-left: 730px;
}

html body .p-r-730px {
  padding-right: 730px;
}

html body .m-t-735px {
  margin-top: 735px;
}

html body .m-b-735px {
  margin-bottom: 735px;
}

html body .m-l-735px {
  margin-left: 735px;
}

html body .m-r-735px {
  margin-right: 735px;
}

html body .p-t-735px {
  padding-top: 735px;
}

html body .p-b-735px {
  padding-bottom: 735px;
}

html body .p-l-735px {
  padding-left: 735px;
}

html body .p-r-735px {
  padding-right: 735px;
}

html body .m-t-740px {
  margin-top: 740px;
}

html body .m-b-740px {
  margin-bottom: 740px;
}

html body .m-l-740px {
  margin-left: 740px;
}

html body .m-r-740px {
  margin-right: 740px;
}

html body .p-t-740px {
  padding-top: 740px;
}

html body .p-b-740px {
  padding-bottom: 740px;
}

html body .p-l-740px {
  padding-left: 740px;
}

html body .p-r-740px {
  padding-right: 740px;
}

html body .m-t-745px {
  margin-top: 745px;
}

html body .m-b-745px {
  margin-bottom: 745px;
}

html body .m-l-745px {
  margin-left: 745px;
}

html body .m-r-745px {
  margin-right: 745px;
}

html body .p-t-745px {
  padding-top: 745px;
}

html body .p-b-745px {
  padding-bottom: 745px;
}

html body .p-l-745px {
  padding-left: 745px;
}

html body .p-r-745px {
  padding-right: 745px;
}

html body .m-t-750px {
  margin-top: 750px;
}

html body .m-b-750px {
  margin-bottom: 750px;
}

html body .m-l-750px {
  margin-left: 750px;
}

html body .m-r-750px {
  margin-right: 750px;
}

html body .p-t-750px {
  padding-top: 750px;
}

html body .p-b-750px {
  padding-bottom: 750px;
}

html body .p-l-750px {
  padding-left: 750px;
}

html body .p-r-750px {
  padding-right: 750px;
}

html body .m-t-755px {
  margin-top: 755px;
}

html body .m-b-755px {
  margin-bottom: 755px;
}

html body .m-l-755px {
  margin-left: 755px;
}

html body .m-r-755px {
  margin-right: 755px;
}

html body .p-t-755px {
  padding-top: 755px;
}

html body .p-b-755px {
  padding-bottom: 755px;
}

html body .p-l-755px {
  padding-left: 755px;
}

html body .p-r-755px {
  padding-right: 755px;
}

html body .m-t-760px {
  margin-top: 760px;
}

html body .m-b-760px {
  margin-bottom: 760px;
}

html body .m-l-760px {
  margin-left: 760px;
}

html body .m-r-760px {
  margin-right: 760px;
}

html body .p-t-760px {
  padding-top: 760px;
}

html body .p-b-760px {
  padding-bottom: 760px;
}

html body .p-l-760px {
  padding-left: 760px;
}

html body .p-r-760px {
  padding-right: 760px;
}

html body .m-t-765px {
  margin-top: 765px;
}

html body .m-b-765px {
  margin-bottom: 765px;
}

html body .m-l-765px {
  margin-left: 765px;
}

html body .m-r-765px {
  margin-right: 765px;
}

html body .p-t-765px {
  padding-top: 765px;
}

html body .p-b-765px {
  padding-bottom: 765px;
}

html body .p-l-765px {
  padding-left: 765px;
}

html body .p-r-765px {
  padding-right: 765px;
}

html body .m-t-770px {
  margin-top: 770px;
}

html body .m-b-770px {
  margin-bottom: 770px;
}

html body .m-l-770px {
  margin-left: 770px;
}

html body .m-r-770px {
  margin-right: 770px;
}

html body .p-t-770px {
  padding-top: 770px;
}

html body .p-b-770px {
  padding-bottom: 770px;
}

html body .p-l-770px {
  padding-left: 770px;
}

html body .p-r-770px {
  padding-right: 770px;
}

html body .m-t-775px {
  margin-top: 775px;
}

html body .m-b-775px {
  margin-bottom: 775px;
}

html body .m-l-775px {
  margin-left: 775px;
}

html body .m-r-775px {
  margin-right: 775px;
}

html body .p-t-775px {
  padding-top: 775px;
}

html body .p-b-775px {
  padding-bottom: 775px;
}

html body .p-l-775px {
  padding-left: 775px;
}

html body .p-r-775px {
  padding-right: 775px;
}

html body .m-t-780px {
  margin-top: 780px;
}

html body .m-b-780px {
  margin-bottom: 780px;
}

html body .m-l-780px {
  margin-left: 780px;
}

html body .m-r-780px {
  margin-right: 780px;
}

html body .p-t-780px {
  padding-top: 780px;
}

html body .p-b-780px {
  padding-bottom: 780px;
}

html body .p-l-780px {
  padding-left: 780px;
}

html body .p-r-780px {
  padding-right: 780px;
}

html body .m-t-785px {
  margin-top: 785px;
}

html body .m-b-785px {
  margin-bottom: 785px;
}

html body .m-l-785px {
  margin-left: 785px;
}

html body .m-r-785px {
  margin-right: 785px;
}

html body .p-t-785px {
  padding-top: 785px;
}

html body .p-b-785px {
  padding-bottom: 785px;
}

html body .p-l-785px {
  padding-left: 785px;
}

html body .p-r-785px {
  padding-right: 785px;
}

html body .m-t-790px {
  margin-top: 790px;
}

html body .m-b-790px {
  margin-bottom: 790px;
}

html body .m-l-790px {
  margin-left: 790px;
}

html body .m-r-790px {
  margin-right: 790px;
}

html body .p-t-790px {
  padding-top: 790px;
}

html body .p-b-790px {
  padding-bottom: 790px;
}

html body .p-l-790px {
  padding-left: 790px;
}

html body .p-r-790px {
  padding-right: 790px;
}

html body .m-t-795px {
  margin-top: 795px;
}

html body .m-b-795px {
  margin-bottom: 795px;
}

html body .m-l-795px {
  margin-left: 795px;
}

html body .m-r-795px {
  margin-right: 795px;
}

html body .p-t-795px {
  padding-top: 795px;
}

html body .p-b-795px {
  padding-bottom: 795px;
}

html body .p-l-795px {
  padding-left: 795px;
}

html body .p-r-795px {
  padding-right: 795px;
}

html body .m-t-800px {
  margin-top: 800px;
}

html body .m-b-800px {
  margin-bottom: 800px;
}

html body .m-l-800px {
  margin-left: 800px;
}

html body .m-r-800px {
  margin-right: 800px;
}

html body .p-t-800px {
  padding-top: 800px;
}

html body .p-b-800px {
  padding-bottom: 800px;
}

html body .p-l-800px {
  padding-left: 800px;
}

html body .p-r-800px {
  padding-right: 800px;
}

html body .m-t-805px {
  margin-top: 805px;
}

html body .m-b-805px {
  margin-bottom: 805px;
}

html body .m-l-805px {
  margin-left: 805px;
}

html body .m-r-805px {
  margin-right: 805px;
}

html body .p-t-805px {
  padding-top: 805px;
}

html body .p-b-805px {
  padding-bottom: 805px;
}

html body .p-l-805px {
  padding-left: 805px;
}

html body .p-r-805px {
  padding-right: 805px;
}

html body .m-t-810px {
  margin-top: 810px;
}

html body .m-b-810px {
  margin-bottom: 810px;
}

html body .m-l-810px {
  margin-left: 810px;
}

html body .m-r-810px {
  margin-right: 810px;
}

html body .p-t-810px {
  padding-top: 810px;
}

html body .p-b-810px {
  padding-bottom: 810px;
}

html body .p-l-810px {
  padding-left: 810px;
}

html body .p-r-810px {
  padding-right: 810px;
}

html body .m-t-815px {
  margin-top: 815px;
}

html body .m-b-815px {
  margin-bottom: 815px;
}

html body .m-l-815px {
  margin-left: 815px;
}

html body .m-r-815px {
  margin-right: 815px;
}

html body .p-t-815px {
  padding-top: 815px;
}

html body .p-b-815px {
  padding-bottom: 815px;
}

html body .p-l-815px {
  padding-left: 815px;
}

html body .p-r-815px {
  padding-right: 815px;
}

html body .m-t-820px {
  margin-top: 820px;
}

html body .m-b-820px {
  margin-bottom: 820px;
}

html body .m-l-820px {
  margin-left: 820px;
}

html body .m-r-820px {
  margin-right: 820px;
}

html body .p-t-820px {
  padding-top: 820px;
}

html body .p-b-820px {
  padding-bottom: 820px;
}

html body .p-l-820px {
  padding-left: 820px;
}

html body .p-r-820px {
  padding-right: 820px;
}

html body .m-t-825px {
  margin-top: 825px;
}

html body .m-b-825px {
  margin-bottom: 825px;
}

html body .m-l-825px {
  margin-left: 825px;
}

html body .m-r-825px {
  margin-right: 825px;
}

html body .p-t-825px {
  padding-top: 825px;
}

html body .p-b-825px {
  padding-bottom: 825px;
}

html body .p-l-825px {
  padding-left: 825px;
}

html body .p-r-825px {
  padding-right: 825px;
}

html body .m-t-830px {
  margin-top: 830px;
}

html body .m-b-830px {
  margin-bottom: 830px;
}

html body .m-l-830px {
  margin-left: 830px;
}

html body .m-r-830px {
  margin-right: 830px;
}

html body .p-t-830px {
  padding-top: 830px;
}

html body .p-b-830px {
  padding-bottom: 830px;
}

html body .p-l-830px {
  padding-left: 830px;
}

html body .p-r-830px {
  padding-right: 830px;
}

html body .m-t-835px {
  margin-top: 835px;
}

html body .m-b-835px {
  margin-bottom: 835px;
}

html body .m-l-835px {
  margin-left: 835px;
}

html body .m-r-835px {
  margin-right: 835px;
}

html body .p-t-835px {
  padding-top: 835px;
}

html body .p-b-835px {
  padding-bottom: 835px;
}

html body .p-l-835px {
  padding-left: 835px;
}

html body .p-r-835px {
  padding-right: 835px;
}

html body .m-t-840px {
  margin-top: 840px;
}

html body .m-b-840px {
  margin-bottom: 840px;
}

html body .m-l-840px {
  margin-left: 840px;
}

html body .m-r-840px {
  margin-right: 840px;
}

html body .p-t-840px {
  padding-top: 840px;
}

html body .p-b-840px {
  padding-bottom: 840px;
}

html body .p-l-840px {
  padding-left: 840px;
}

html body .p-r-840px {
  padding-right: 840px;
}

html body .m-t-845px {
  margin-top: 845px;
}

html body .m-b-845px {
  margin-bottom: 845px;
}

html body .m-l-845px {
  margin-left: 845px;
}

html body .m-r-845px {
  margin-right: 845px;
}

html body .p-t-845px {
  padding-top: 845px;
}

html body .p-b-845px {
  padding-bottom: 845px;
}

html body .p-l-845px {
  padding-left: 845px;
}

html body .p-r-845px {
  padding-right: 845px;
}

html body .m-t-850px {
  margin-top: 850px;
}

html body .m-b-850px {
  margin-bottom: 850px;
}

html body .m-l-850px {
  margin-left: 850px;
}

html body .m-r-850px {
  margin-right: 850px;
}

html body .p-t-850px {
  padding-top: 850px;
}

html body .p-b-850px {
  padding-bottom: 850px;
}

html body .p-l-850px {
  padding-left: 850px;
}

html body .p-r-850px {
  padding-right: 850px;
}

html body .m-t-855px {
  margin-top: 855px;
}

html body .m-b-855px {
  margin-bottom: 855px;
}

html body .m-l-855px {
  margin-left: 855px;
}

html body .m-r-855px {
  margin-right: 855px;
}

html body .p-t-855px {
  padding-top: 855px;
}

html body .p-b-855px {
  padding-bottom: 855px;
}

html body .p-l-855px {
  padding-left: 855px;
}

html body .p-r-855px {
  padding-right: 855px;
}

html body .m-t-860px {
  margin-top: 860px;
}

html body .m-b-860px {
  margin-bottom: 860px;
}

html body .m-l-860px {
  margin-left: 860px;
}

html body .m-r-860px {
  margin-right: 860px;
}

html body .p-t-860px {
  padding-top: 860px;
}

html body .p-b-860px {
  padding-bottom: 860px;
}

html body .p-l-860px {
  padding-left: 860px;
}

html body .p-r-860px {
  padding-right: 860px;
}

html body .m-t-865px {
  margin-top: 865px;
}

html body .m-b-865px {
  margin-bottom: 865px;
}

html body .m-l-865px {
  margin-left: 865px;
}

html body .m-r-865px {
  margin-right: 865px;
}

html body .p-t-865px {
  padding-top: 865px;
}

html body .p-b-865px {
  padding-bottom: 865px;
}

html body .p-l-865px {
  padding-left: 865px;
}

html body .p-r-865px {
  padding-right: 865px;
}

html body .m-t-870px {
  margin-top: 870px;
}

html body .m-b-870px {
  margin-bottom: 870px;
}

html body .m-l-870px {
  margin-left: 870px;
}

html body .m-r-870px {
  margin-right: 870px;
}

html body .p-t-870px {
  padding-top: 870px;
}

html body .p-b-870px {
  padding-bottom: 870px;
}

html body .p-l-870px {
  padding-left: 870px;
}

html body .p-r-870px {
  padding-right: 870px;
}

html body .m-t-875px {
  margin-top: 875px;
}

html body .m-b-875px {
  margin-bottom: 875px;
}

html body .m-l-875px {
  margin-left: 875px;
}

html body .m-r-875px {
  margin-right: 875px;
}

html body .p-t-875px {
  padding-top: 875px;
}

html body .p-b-875px {
  padding-bottom: 875px;
}

html body .p-l-875px {
  padding-left: 875px;
}

html body .p-r-875px {
  padding-right: 875px;
}

html body .m-t-880px {
  margin-top: 880px;
}

html body .m-b-880px {
  margin-bottom: 880px;
}

html body .m-l-880px {
  margin-left: 880px;
}

html body .m-r-880px {
  margin-right: 880px;
}

html body .p-t-880px {
  padding-top: 880px;
}

html body .p-b-880px {
  padding-bottom: 880px;
}

html body .p-l-880px {
  padding-left: 880px;
}

html body .p-r-880px {
  padding-right: 880px;
}

html body .m-t-885px {
  margin-top: 885px;
}

html body .m-b-885px {
  margin-bottom: 885px;
}

html body .m-l-885px {
  margin-left: 885px;
}

html body .m-r-885px {
  margin-right: 885px;
}

html body .p-t-885px {
  padding-top: 885px;
}

html body .p-b-885px {
  padding-bottom: 885px;
}

html body .p-l-885px {
  padding-left: 885px;
}

html body .p-r-885px {
  padding-right: 885px;
}

html body .m-t-890px {
  margin-top: 890px;
}

html body .m-b-890px {
  margin-bottom: 890px;
}

html body .m-l-890px {
  margin-left: 890px;
}

html body .m-r-890px {
  margin-right: 890px;
}

html body .p-t-890px {
  padding-top: 890px;
}

html body .p-b-890px {
  padding-bottom: 890px;
}

html body .p-l-890px {
  padding-left: 890px;
}

html body .p-r-890px {
  padding-right: 890px;
}

html body .m-t-895px {
  margin-top: 895px;
}

html body .m-b-895px {
  margin-bottom: 895px;
}

html body .m-l-895px {
  margin-left: 895px;
}

html body .m-r-895px {
  margin-right: 895px;
}

html body .p-t-895px {
  padding-top: 895px;
}

html body .p-b-895px {
  padding-bottom: 895px;
}

html body .p-l-895px {
  padding-left: 895px;
}

html body .p-r-895px {
  padding-right: 895px;
}

html body .m-t-900px {
  margin-top: 900px;
}

html body .m-b-900px {
  margin-bottom: 900px;
}

html body .m-l-900px {
  margin-left: 900px;
}

html body .m-r-900px {
  margin-right: 900px;
}

html body .p-t-900px {
  padding-top: 900px;
}

html body .p-b-900px {
  padding-bottom: 900px;
}

html body .p-l-900px {
  padding-left: 900px;
}

html body .p-r-900px {
  padding-right: 900px;
}

html body .m-t-905px {
  margin-top: 905px;
}

html body .m-b-905px {
  margin-bottom: 905px;
}

html body .m-l-905px {
  margin-left: 905px;
}

html body .m-r-905px {
  margin-right: 905px;
}

html body .p-t-905px {
  padding-top: 905px;
}

html body .p-b-905px {
  padding-bottom: 905px;
}

html body .p-l-905px {
  padding-left: 905px;
}

html body .p-r-905px {
  padding-right: 905px;
}

html body .m-t-910px {
  margin-top: 910px;
}

html body .m-b-910px {
  margin-bottom: 910px;
}

html body .m-l-910px {
  margin-left: 910px;
}

html body .m-r-910px {
  margin-right: 910px;
}

html body .p-t-910px {
  padding-top: 910px;
}

html body .p-b-910px {
  padding-bottom: 910px;
}

html body .p-l-910px {
  padding-left: 910px;
}

html body .p-r-910px {
  padding-right: 910px;
}

html body .m-t-915px {
  margin-top: 915px;
}

html body .m-b-915px {
  margin-bottom: 915px;
}

html body .m-l-915px {
  margin-left: 915px;
}

html body .m-r-915px {
  margin-right: 915px;
}

html body .p-t-915px {
  padding-top: 915px;
}

html body .p-b-915px {
  padding-bottom: 915px;
}

html body .p-l-915px {
  padding-left: 915px;
}

html body .p-r-915px {
  padding-right: 915px;
}

html body .m-t-920px {
  margin-top: 920px;
}

html body .m-b-920px {
  margin-bottom: 920px;
}

html body .m-l-920px {
  margin-left: 920px;
}

html body .m-r-920px {
  margin-right: 920px;
}

html body .p-t-920px {
  padding-top: 920px;
}

html body .p-b-920px {
  padding-bottom: 920px;
}

html body .p-l-920px {
  padding-left: 920px;
}

html body .p-r-920px {
  padding-right: 920px;
}

html body .m-t-925px {
  margin-top: 925px;
}

html body .m-b-925px {
  margin-bottom: 925px;
}

html body .m-l-925px {
  margin-left: 925px;
}

html body .m-r-925px {
  margin-right: 925px;
}

html body .p-t-925px {
  padding-top: 925px;
}

html body .p-b-925px {
  padding-bottom: 925px;
}

html body .p-l-925px {
  padding-left: 925px;
}

html body .p-r-925px {
  padding-right: 925px;
}

html body .m-t-930px {
  margin-top: 930px;
}

html body .m-b-930px {
  margin-bottom: 930px;
}

html body .m-l-930px {
  margin-left: 930px;
}

html body .m-r-930px {
  margin-right: 930px;
}

html body .p-t-930px {
  padding-top: 930px;
}

html body .p-b-930px {
  padding-bottom: 930px;
}

html body .p-l-930px {
  padding-left: 930px;
}

html body .p-r-930px {
  padding-right: 930px;
}

html body .m-t-935px {
  margin-top: 935px;
}

html body .m-b-935px {
  margin-bottom: 935px;
}

html body .m-l-935px {
  margin-left: 935px;
}

html body .m-r-935px {
  margin-right: 935px;
}

html body .p-t-935px {
  padding-top: 935px;
}

html body .p-b-935px {
  padding-bottom: 935px;
}

html body .p-l-935px {
  padding-left: 935px;
}

html body .p-r-935px {
  padding-right: 935px;
}

html body .m-t-940px {
  margin-top: 940px;
}

html body .m-b-940px {
  margin-bottom: 940px;
}

html body .m-l-940px {
  margin-left: 940px;
}

html body .m-r-940px {
  margin-right: 940px;
}

html body .p-t-940px {
  padding-top: 940px;
}

html body .p-b-940px {
  padding-bottom: 940px;
}

html body .p-l-940px {
  padding-left: 940px;
}

html body .p-r-940px {
  padding-right: 940px;
}

html body .m-t-945px {
  margin-top: 945px;
}

html body .m-b-945px {
  margin-bottom: 945px;
}

html body .m-l-945px {
  margin-left: 945px;
}

html body .m-r-945px {
  margin-right: 945px;
}

html body .p-t-945px {
  padding-top: 945px;
}

html body .p-b-945px {
  padding-bottom: 945px;
}

html body .p-l-945px {
  padding-left: 945px;
}

html body .p-r-945px {
  padding-right: 945px;
}

html body .m-t-950px {
  margin-top: 950px;
}

html body .m-b-950px {
  margin-bottom: 950px;
}

html body .m-l-950px {
  margin-left: 950px;
}

html body .m-r-950px {
  margin-right: 950px;
}

html body .p-t-950px {
  padding-top: 950px;
}

html body .p-b-950px {
  padding-bottom: 950px;
}

html body .p-l-950px {
  padding-left: 950px;
}

html body .p-r-950px {
  padding-right: 950px;
}

html body .m-t-955px {
  margin-top: 955px;
}

html body .m-b-955px {
  margin-bottom: 955px;
}

html body .m-l-955px {
  margin-left: 955px;
}

html body .m-r-955px {
  margin-right: 955px;
}

html body .p-t-955px {
  padding-top: 955px;
}

html body .p-b-955px {
  padding-bottom: 955px;
}

html body .p-l-955px {
  padding-left: 955px;
}

html body .p-r-955px {
  padding-right: 955px;
}

html body .m-t-960px {
  margin-top: 960px;
}

html body .m-b-960px {
  margin-bottom: 960px;
}

html body .m-l-960px {
  margin-left: 960px;
}

html body .m-r-960px {
  margin-right: 960px;
}

html body .p-t-960px {
  padding-top: 960px;
}

html body .p-b-960px {
  padding-bottom: 960px;
}

html body .p-l-960px {
  padding-left: 960px;
}

html body .p-r-960px {
  padding-right: 960px;
}

html body .m-t-965px {
  margin-top: 965px;
}

html body .m-b-965px {
  margin-bottom: 965px;
}

html body .m-l-965px {
  margin-left: 965px;
}

html body .m-r-965px {
  margin-right: 965px;
}

html body .p-t-965px {
  padding-top: 965px;
}

html body .p-b-965px {
  padding-bottom: 965px;
}

html body .p-l-965px {
  padding-left: 965px;
}

html body .p-r-965px {
  padding-right: 965px;
}

html body .m-t-970px {
  margin-top: 970px;
}

html body .m-b-970px {
  margin-bottom: 970px;
}

html body .m-l-970px {
  margin-left: 970px;
}

html body .m-r-970px {
  margin-right: 970px;
}

html body .p-t-970px {
  padding-top: 970px;
}

html body .p-b-970px {
  padding-bottom: 970px;
}

html body .p-l-970px {
  padding-left: 970px;
}

html body .p-r-970px {
  padding-right: 970px;
}

html body .m-t-975px {
  margin-top: 975px;
}

html body .m-b-975px {
  margin-bottom: 975px;
}

html body .m-l-975px {
  margin-left: 975px;
}

html body .m-r-975px {
  margin-right: 975px;
}

html body .p-t-975px {
  padding-top: 975px;
}

html body .p-b-975px {
  padding-bottom: 975px;
}

html body .p-l-975px {
  padding-left: 975px;
}

html body .p-r-975px {
  padding-right: 975px;
}

html body .m-t-980px {
  margin-top: 980px;
}

html body .m-b-980px {
  margin-bottom: 980px;
}

html body .m-l-980px {
  margin-left: 980px;
}

html body .m-r-980px {
  margin-right: 980px;
}

html body .p-t-980px {
  padding-top: 980px;
}

html body .p-b-980px {
  padding-bottom: 980px;
}

html body .p-l-980px {
  padding-left: 980px;
}

html body .p-r-980px {
  padding-right: 980px;
}

html body .m-t-985px {
  margin-top: 985px;
}

html body .m-b-985px {
  margin-bottom: 985px;
}

html body .m-l-985px {
  margin-left: 985px;
}

html body .m-r-985px {
  margin-right: 985px;
}

html body .p-t-985px {
  padding-top: 985px;
}

html body .p-b-985px {
  padding-bottom: 985px;
}

html body .p-l-985px {
  padding-left: 985px;
}

html body .p-r-985px {
  padding-right: 985px;
}

html body .m-t-990px {
  margin-top: 990px;
}

html body .m-b-990px {
  margin-bottom: 990px;
}

html body .m-l-990px {
  margin-left: 990px;
}

html body .m-r-990px {
  margin-right: 990px;
}

html body .p-t-990px {
  padding-top: 990px;
}

html body .p-b-990px {
  padding-bottom: 990px;
}

html body .p-l-990px {
  padding-left: 990px;
}

html body .p-r-990px {
  padding-right: 990px;
}

html body .m-t-995px {
  margin-top: 995px;
}

html body .m-b-995px {
  margin-bottom: 995px;
}

html body .m-l-995px {
  margin-left: 995px;
}

html body .m-r-995px {
  margin-right: 995px;
}

html body .p-t-995px {
  padding-top: 995px;
}

html body .p-b-995px {
  padding-bottom: 995px;
}

html body .p-l-995px {
  padding-left: 995px;
}

html body .p-r-995px {
  padding-right: 995px;
}

html body .m-t-1000px {
  margin-top: 1000px;
}

html body .m-b-1000px {
  margin-bottom: 1000px;
}

html body .m-l-1000px {
  margin-left: 1000px;
}

html body .m-r-1000px {
  margin-right: 1000px;
}

html body .p-t-1000px {
  padding-top: 1000px;
}

html body .p-b-1000px {
  padding-bottom: 1000px;
}

html body .p-l-1000px {
  padding-left: 1000px;
}

html body .p-r-1000px {
  padding-right: 1000px;
}

html body .m-t-1px {
  margin-top: 1px;
}

html body .m-b-1px {
  margin-bottom: 1px;
}

html body .m-l-1px {
  margin-left: 1px;
}

html body .m-r-1px {
  margin-right: 1px;
}

html body .p-t-1px {
  padding-top: 1px;
}

html body .p-b-1px {
  padding-bottom: 1px;
}

html body .p-l-1px {
  padding-left: 1px;
}

html body .p-r-1px {
  padding-right: 1px;
}

html body .m-t-2px {
  margin-top: 2px;
}

html body .m-b-2px {
  margin-bottom: 2px;
}

html body .m-l-2px {
  margin-left: 2px;
}

html body .m-r-2px {
  margin-right: 2px;
}

html body .p-t-2px {
  padding-top: 2px;
}

html body .p-b-2px {
  padding-bottom: 2px;
}

html body .p-l-2px {
  padding-left: 2px;
}

html body .p-r-2px {
  padding-right: 2px;
}

html body .m-t-3px {
  margin-top: 3px;
}

html body .m-b-3px {
  margin-bottom: 3px;
}

html body .m-l-3px {
  margin-left: 3px;
}

html body .m-r-3px {
  margin-right: 3px;
}

html body .p-t-3px {
  padding-top: 3px;
}

html body .p-b-3px {
  padding-bottom: 3px;
}

html body .p-l-3px {
  padding-left: 3px;
}

html body .p-r-3px {
  padding-right: 3px;
}

html body .m-t-4px {
  margin-top: 4px;
}

html body .m-b-4px {
  margin-bottom: 4px;
}

html body .m-l-4px {
  margin-left: 4px;
}

html body .m-r-4px {
  margin-right: 4px;
}

html body .p-t-4px {
  padding-top: 4px;
}

html body .p-b-4px {
  padding-bottom: 4px;
}

html body .p-l-4px {
  padding-left: 4px;
}

html body .p-r-4px {
  padding-right: 4px;
}

html body .m-t-5px {
  margin-top: 5px;
}

html body .m-b-5px {
  margin-bottom: 5px;
}

html body .m-l-5px {
  margin-left: 5px;
}

html body .m-r-5px {
  margin-right: 5px;
}

html body .p-t-5px {
  padding-top: 5px;
}

html body .p-b-5px {
  padding-bottom: 5px;
}

html body .p-l-5px {
  padding-left: 5px;
}

html body .p-r-5px {
  padding-right: 5px;
}

html body .m-t-6px {
  margin-top: 6px;
}

html body .m-b-6px {
  margin-bottom: 6px;
}

html body .m-l-6px {
  margin-left: 6px;
}

html body .m-r-6px {
  margin-right: 6px;
}

html body .p-t-6px {
  padding-top: 6px;
}

html body .p-b-6px {
  padding-bottom: 6px;
}

html body .p-l-6px {
  padding-left: 6px;
}

html body .p-r-6px {
  padding-right: 6px;
}

html body .m-t-7px {
  margin-top: 7px;
}

html body .m-b-7px {
  margin-bottom: 7px;
}

html body .m-l-7px {
  margin-left: 7px;
}

html body .m-r-7px {
  margin-right: 7px;
}

html body .p-t-7px {
  padding-top: 7px;
}

html body .p-b-7px {
  padding-bottom: 7px;
}

html body .p-l-7px {
  padding-left: 7px;
}

html body .p-r-7px {
  padding-right: 7px;
}

html body .m-t-8px {
  margin-top: 8px;
}

html body .m-b-8px {
  margin-bottom: 8px;
}

html body .m-l-8px {
  margin-left: 8px;
}

html body .m-r-8px {
  margin-right: 8px;
}

html body .p-t-8px {
  padding-top: 8px;
}

html body .p-b-8px {
  padding-bottom: 8px;
}

html body .p-l-8px {
  padding-left: 8px;
}

html body .p-r-8px {
  padding-right: 8px;
}

html body .m-t-9px {
  margin-top: 9px;
}

html body .m-b-9px {
  margin-bottom: 9px;
}

html body .m-l-9px {
  margin-left: 9px;
}

html body .m-r-9px {
  margin-right: 9px;
}

html body .p-t-9px {
  padding-top: 9px;
}

html body .p-b-9px {
  padding-bottom: 9px;
}

html body .p-l-9px {
  padding-left: 9px;
}

html body .p-r-9px {
  padding-right: 9px;
}

html body .m-t-10px {
  margin-top: 10px;
}

html body .m-b-10px {
  margin-bottom: 10px;
}

html body .m-l-10px {
  margin-left: 10px;
}

html body .m-r-10px {
  margin-right: 10px;
}

html body .p-t-10px {
  padding-top: 10px;
}

html body .p-b-10px {
  padding-bottom: 10px;
}

html body .p-l-10px {
  padding-left: 10px;
}

html body .p-r-10px {
  padding-right: 10px;
}

html body .m-t-11px {
  margin-top: 11px;
}

html body .m-b-11px {
  margin-bottom: 11px;
}

html body .m-l-11px {
  margin-left: 11px;
}

html body .m-r-11px {
  margin-right: 11px;
}

html body .p-t-11px {
  padding-top: 11px;
}

html body .p-b-11px {
  padding-bottom: 11px;
}

html body .p-l-11px {
  padding-left: 11px;
}

html body .p-r-11px {
  padding-right: 11px;
}

html body .m-t-12px {
  margin-top: 12px;
}

html body .m-b-12px {
  margin-bottom: 12px;
}

html body .m-l-12px {
  margin-left: 12px;
}

html body .m-r-12px {
  margin-right: 12px;
}

html body .p-t-12px {
  padding-top: 12px;
}

html body .p-b-12px {
  padding-bottom: 12px;
}

html body .p-l-12px {
  padding-left: 12px;
}

html body .p-r-12px {
  padding-right: 12px;
}

html body .m-t-13px {
  margin-top: 13px;
}

html body .m-b-13px {
  margin-bottom: 13px;
}

html body .m-l-13px {
  margin-left: 13px;
}

html body .m-r-13px {
  margin-right: 13px;
}

html body .p-t-13px {
  padding-top: 13px;
}

html body .p-b-13px {
  padding-bottom: 13px;
}

html body .p-l-13px {
  padding-left: 13px;
}

html body .p-r-13px {
  padding-right: 13px;
}

html body .m-t-14px {
  margin-top: 14px;
}

html body .m-b-14px {
  margin-bottom: 14px;
}

html body .m-l-14px {
  margin-left: 14px;
}

html body .m-r-14px {
  margin-right: 14px;
}

html body .p-t-14px {
  padding-top: 14px;
}

html body .p-b-14px {
  padding-bottom: 14px;
}

html body .p-l-14px {
  padding-left: 14px;
}

html body .p-r-14px {
  padding-right: 14px;
}

html body .m-t-15px {
  margin-top: 15px;
}

html body .m-b-15px {
  margin-bottom: 15px;
}

html body .m-l-15px {
  margin-left: 15px;
}

html body .m-r-15px {
  margin-right: 15px;
}

html body .p-t-15px {
  padding-top: 15px;
}

html body .p-b-15px {
  padding-bottom: 15px;
}

html body .p-l-15px {
  padding-left: 15px;
}

html body .p-r-15px {
  padding-right: 15px;
}

html body .m-t-16px {
  margin-top: 16px;
}

html body .m-b-16px {
  margin-bottom: 16px;
}

html body .m-l-16px {
  margin-left: 16px;
}

html body .m-r-16px {
  margin-right: 16px;
}

html body .p-t-16px {
  padding-top: 16px;
}

html body .p-b-16px {
  padding-bottom: 16px;
}

html body .p-l-16px {
  padding-left: 16px;
}

html body .p-r-16px {
  padding-right: 16px;
}

html body .m-t-17px {
  margin-top: 17px;
}

html body .m-b-17px {
  margin-bottom: 17px;
}

html body .m-l-17px {
  margin-left: 17px;
}

html body .m-r-17px {
  margin-right: 17px;
}

html body .p-t-17px {
  padding-top: 17px;
}

html body .p-b-17px {
  padding-bottom: 17px;
}

html body .p-l-17px {
  padding-left: 17px;
}

html body .p-r-17px {
  padding-right: 17px;
}

html body .m-t-18px {
  margin-top: 18px;
}

html body .m-b-18px {
  margin-bottom: 18px;
}

html body .m-l-18px {
  margin-left: 18px;
}

html body .m-r-18px {
  margin-right: 18px;
}

html body .p-t-18px {
  padding-top: 18px;
}

html body .p-b-18px {
  padding-bottom: 18px;
}

html body .p-l-18px {
  padding-left: 18px;
}

html body .p-r-18px {
  padding-right: 18px;
}

html body .m-t-19px {
  margin-top: 19px;
}

html body .m-b-19px {
  margin-bottom: 19px;
}

html body .m-l-19px {
  margin-left: 19px;
}

html body .m-r-19px {
  margin-right: 19px;
}

html body .p-t-19px {
  padding-top: 19px;
}

html body .p-b-19px {
  padding-bottom: 19px;
}

html body .p-l-19px {
  padding-left: 19px;
}

html body .p-r-19px {
  padding-right: 19px;
}

html body .m-t-20px {
  margin-top: 20px;
}

html body .m-b-20px {
  margin-bottom: 20px;
}

html body .m-l-20px {
  margin-left: 20px;
}

html body .m-r-20px {
  margin-right: 20px;
}

html body .p-t-20px {
  padding-top: 20px;
}

html body .p-b-20px {
  padding-bottom: 20px;
}

html body .p-l-20px {
  padding-left: 20px;
}

html body .p-r-20px {
  padding-right: 20px;
}

html body .m-t-21px {
  margin-top: 21px;
}

html body .m-b-21px {
  margin-bottom: 21px;
}

html body .m-l-21px {
  margin-left: 21px;
}

html body .m-r-21px {
  margin-right: 21px;
}

html body .p-t-21px {
  padding-top: 21px;
}

html body .p-b-21px {
  padding-bottom: 21px;
}

html body .p-l-21px {
  padding-left: 21px;
}

html body .p-r-21px {
  padding-right: 21px;
}

html body .m-t-22px {
  margin-top: 22px;
}

html body .m-b-22px {
  margin-bottom: 22px;
}

html body .m-l-22px {
  margin-left: 22px;
}

html body .m-r-22px {
  margin-right: 22px;
}

html body .p-t-22px {
  padding-top: 22px;
}

html body .p-b-22px {
  padding-bottom: 22px;
}

html body .p-l-22px {
  padding-left: 22px;
}

html body .p-r-22px {
  padding-right: 22px;
}

html body .m-t-23px {
  margin-top: 23px;
}

html body .m-b-23px {
  margin-bottom: 23px;
}

html body .m-l-23px {
  margin-left: 23px;
}

html body .m-r-23px {
  margin-right: 23px;
}

html body .p-t-23px {
  padding-top: 23px;
}

html body .p-b-23px {
  padding-bottom: 23px;
}

html body .p-l-23px {
  padding-left: 23px;
}

html body .p-r-23px {
  padding-right: 23px;
}

html body .m-t-24px {
  margin-top: 24px;
}

html body .m-b-24px {
  margin-bottom: 24px;
}

html body .m-l-24px {
  margin-left: 24px;
}

html body .m-r-24px {
  margin-right: 24px;
}

html body .p-t-24px {
  padding-top: 24px;
}

html body .p-b-24px {
  padding-bottom: 24px;
}

html body .p-l-24px {
  padding-left: 24px;
}

html body .p-r-24px {
  padding-right: 24px;
}

html body .m-t-25px {
  margin-top: 25px;
}

html body .m-b-25px {
  margin-bottom: 25px;
}

html body .m-l-25px {
  margin-left: 25px;
}

html body .m-r-25px {
  margin-right: 25px;
}

html body .p-t-25px {
  padding-top: 25px;
}

html body .p-b-25px {
  padding-bottom: 25px;
}

html body .p-l-25px {
  padding-left: 25px;
}

html body .p-r-25px {
  padding-right: 25px;
}

html body .m-t-26px {
  margin-top: 26px;
}

html body .m-b-26px {
  margin-bottom: 26px;
}

html body .m-l-26px {
  margin-left: 26px;
}

html body .m-r-26px {
  margin-right: 26px;
}

html body .p-t-26px {
  padding-top: 26px;
}

html body .p-b-26px {
  padding-bottom: 26px;
}

html body .p-l-26px {
  padding-left: 26px;
}

html body .p-r-26px {
  padding-right: 26px;
}

html body .m-t-27px {
  margin-top: 27px;
}

html body .m-b-27px {
  margin-bottom: 27px;
}

html body .m-l-27px {
  margin-left: 27px;
}

html body .m-r-27px {
  margin-right: 27px;
}

html body .p-t-27px {
  padding-top: 27px;
}

html body .p-b-27px {
  padding-bottom: 27px;
}

html body .p-l-27px {
  padding-left: 27px;
}

html body .p-r-27px {
  padding-right: 27px;
}

html body .m-t-28px {
  margin-top: 28px;
}

html body .m-b-28px {
  margin-bottom: 28px;
}

html body .m-l-28px {
  margin-left: 28px;
}

html body .m-r-28px {
  margin-right: 28px;
}

html body .p-t-28px {
  padding-top: 28px;
}

html body .p-b-28px {
  padding-bottom: 28px;
}

html body .p-l-28px {
  padding-left: 28px;
}

html body .p-r-28px {
  padding-right: 28px;
}

html body .m-t-29px {
  margin-top: 29px;
}

html body .m-b-29px {
  margin-bottom: 29px;
}

html body .m-l-29px {
  margin-left: 29px;
}

html body .m-r-29px {
  margin-right: 29px;
}

html body .p-t-29px {
  padding-top: 29px;
}

html body .p-b-29px {
  padding-bottom: 29px;
}

html body .p-l-29px {
  padding-left: 29px;
}

html body .p-r-29px {
  padding-right: 29px;
}

html body .m-t-30px {
  margin-top: 30px;
}

html body .m-b-30px {
  margin-bottom: 30px;
}

html body .m-l-30px {
  margin-left: 30px;
}

html body .m-r-30px {
  margin-right: 30px;
}

html body .p-t-30px {
  padding-top: 30px;
}

html body .p-b-30px {
  padding-bottom: 30px;
}

html body .p-l-30px {
  padding-left: 30px;
}

html body .p-r-30px {
  padding-right: 30px;
}

html body .m-t-31px {
  margin-top: 31px;
}

html body .m-b-31px {
  margin-bottom: 31px;
}

html body .m-l-31px {
  margin-left: 31px;
}

html body .m-r-31px {
  margin-right: 31px;
}

html body .p-t-31px {
  padding-top: 31px;
}

html body .p-b-31px {
  padding-bottom: 31px;
}

html body .p-l-31px {
  padding-left: 31px;
}

html body .p-r-31px {
  padding-right: 31px;
}

html body .m-t-32px {
  margin-top: 32px;
}

html body .m-b-32px {
  margin-bottom: 32px;
}

html body .m-l-32px {
  margin-left: 32px;
}

html body .m-r-32px {
  margin-right: 32px;
}

html body .p-t-32px {
  padding-top: 32px;
}

html body .p-b-32px {
  padding-bottom: 32px;
}

html body .p-l-32px {
  padding-left: 32px;
}

html body .p-r-32px {
  padding-right: 32px;
}

html body .m-t-33px {
  margin-top: 33px;
}

html body .m-b-33px {
  margin-bottom: 33px;
}

html body .m-l-33px {
  margin-left: 33px;
}

html body .m-r-33px {
  margin-right: 33px;
}

html body .p-t-33px {
  padding-top: 33px;
}

html body .p-b-33px {
  padding-bottom: 33px;
}

html body .p-l-33px {
  padding-left: 33px;
}

html body .p-r-33px {
  padding-right: 33px;
}

html body .m-t-34px {
  margin-top: 34px;
}

html body .m-b-34px {
  margin-bottom: 34px;
}

html body .m-l-34px {
  margin-left: 34px;
}

html body .m-r-34px {
  margin-right: 34px;
}

html body .p-t-34px {
  padding-top: 34px;
}

html body .p-b-34px {
  padding-bottom: 34px;
}

html body .p-l-34px {
  padding-left: 34px;
}

html body .p-r-34px {
  padding-right: 34px;
}

html body .m-t-35px {
  margin-top: 35px;
}

html body .m-b-35px {
  margin-bottom: 35px;
}

html body .m-l-35px {
  margin-left: 35px;
}

html body .m-r-35px {
  margin-right: 35px;
}

html body .p-t-35px {
  padding-top: 35px;
}

html body .p-b-35px {
  padding-bottom: 35px;
}

html body .p-l-35px {
  padding-left: 35px;
}

html body .p-r-35px {
  padding-right: 35px;
}

html body .m-t-36px {
  margin-top: 36px;
}

html body .m-b-36px {
  margin-bottom: 36px;
}

html body .m-l-36px {
  margin-left: 36px;
}

html body .m-r-36px {
  margin-right: 36px;
}

html body .p-t-36px {
  padding-top: 36px;
}

html body .p-b-36px {
  padding-bottom: 36px;
}

html body .p-l-36px {
  padding-left: 36px;
}

html body .p-r-36px {
  padding-right: 36px;
}

html body .m-t-37px {
  margin-top: 37px;
}

html body .m-b-37px {
  margin-bottom: 37px;
}

html body .m-l-37px {
  margin-left: 37px;
}

html body .m-r-37px {
  margin-right: 37px;
}

html body .p-t-37px {
  padding-top: 37px;
}

html body .p-b-37px {
  padding-bottom: 37px;
}

html body .p-l-37px {
  padding-left: 37px;
}

html body .p-r-37px {
  padding-right: 37px;
}

html body .m-t-38px {
  margin-top: 38px;
}

html body .m-b-38px {
  margin-bottom: 38px;
}

html body .m-l-38px {
  margin-left: 38px;
}

html body .m-r-38px {
  margin-right: 38px;
}

html body .p-t-38px {
  padding-top: 38px;
}

html body .p-b-38px {
  padding-bottom: 38px;
}

html body .p-l-38px {
  padding-left: 38px;
}

html body .p-r-38px {
  padding-right: 38px;
}

html body .m-t-39px {
  margin-top: 39px;
}

html body .m-b-39px {
  margin-bottom: 39px;
}

html body .m-l-39px {
  margin-left: 39px;
}

html body .m-r-39px {
  margin-right: 39px;
}

html body .p-t-39px {
  padding-top: 39px;
}

html body .p-b-39px {
  padding-bottom: 39px;
}

html body .p-l-39px {
  padding-left: 39px;
}

html body .p-r-39px {
  padding-right: 39px;
}

html body .m-t-40px {
  margin-top: 40px;
}

html body .m-b-40px {
  margin-bottom: 40px;
}

html body .m-l-40px {
  margin-left: 40px;
}

html body .m-r-40px {
  margin-right: 40px;
}

html body .p-t-40px {
  padding-top: 40px;
}

html body .p-b-40px {
  padding-bottom: 40px;
}

html body .p-l-40px {
  padding-left: 40px;
}

html body .p-r-40px {
  padding-right: 40px;
}

html body .m-t-41px {
  margin-top: 41px;
}

html body .m-b-41px {
  margin-bottom: 41px;
}

html body .m-l-41px {
  margin-left: 41px;
}

html body .m-r-41px {
  margin-right: 41px;
}

html body .p-t-41px {
  padding-top: 41px;
}

html body .p-b-41px {
  padding-bottom: 41px;
}

html body .p-l-41px {
  padding-left: 41px;
}

html body .p-r-41px {
  padding-right: 41px;
}

html body .m-t-42px {
  margin-top: 42px;
}

html body .m-b-42px {
  margin-bottom: 42px;
}

html body .m-l-42px {
  margin-left: 42px;
}

html body .m-r-42px {
  margin-right: 42px;
}

html body .p-t-42px {
  padding-top: 42px;
}

html body .p-b-42px {
  padding-bottom: 42px;
}

html body .p-l-42px {
  padding-left: 42px;
}

html body .p-r-42px {
  padding-right: 42px;
}

html body .m-t-43px {
  margin-top: 43px;
}

html body .m-b-43px {
  margin-bottom: 43px;
}

html body .m-l-43px {
  margin-left: 43px;
}

html body .m-r-43px {
  margin-right: 43px;
}

html body .p-t-43px {
  padding-top: 43px;
}

html body .p-b-43px {
  padding-bottom: 43px;
}

html body .p-l-43px {
  padding-left: 43px;
}

html body .p-r-43px {
  padding-right: 43px;
}

html body .m-t-44px {
  margin-top: 44px;
}

html body .m-b-44px {
  margin-bottom: 44px;
}

html body .m-l-44px {
  margin-left: 44px;
}

html body .m-r-44px {
  margin-right: 44px;
}

html body .p-t-44px {
  padding-top: 44px;
}

html body .p-b-44px {
  padding-bottom: 44px;
}

html body .p-l-44px {
  padding-left: 44px;
}

html body .p-r-44px {
  padding-right: 44px;
}

html body .m-t-45px {
  margin-top: 45px;
}

html body .m-b-45px {
  margin-bottom: 45px;
}

html body .m-l-45px {
  margin-left: 45px;
}

html body .m-r-45px {
  margin-right: 45px;
}

html body .p-t-45px {
  padding-top: 45px;
}

html body .p-b-45px {
  padding-bottom: 45px;
}

html body .p-l-45px {
  padding-left: 45px;
}

html body .p-r-45px {
  padding-right: 45px;
}

html body .m-t-46px {
  margin-top: 46px;
}

html body .m-b-46px {
  margin-bottom: 46px;
}

html body .m-l-46px {
  margin-left: 46px;
}

html body .m-r-46px {
  margin-right: 46px;
}

html body .p-t-46px {
  padding-top: 46px;
}

html body .p-b-46px {
  padding-bottom: 46px;
}

html body .p-l-46px {
  padding-left: 46px;
}

html body .p-r-46px {
  padding-right: 46px;
}

html body .m-t-47px {
  margin-top: 47px;
}

html body .m-b-47px {
  margin-bottom: 47px;
}

html body .m-l-47px {
  margin-left: 47px;
}

html body .m-r-47px {
  margin-right: 47px;
}

html body .p-t-47px {
  padding-top: 47px;
}

html body .p-b-47px {
  padding-bottom: 47px;
}

html body .p-l-47px {
  padding-left: 47px;
}

html body .p-r-47px {
  padding-right: 47px;
}

html body .m-t-48px {
  margin-top: 48px;
}

html body .m-b-48px {
  margin-bottom: 48px;
}

html body .m-l-48px {
  margin-left: 48px;
}

html body .m-r-48px {
  margin-right: 48px;
}

html body .p-t-48px {
  padding-top: 48px;
}

html body .p-b-48px {
  padding-bottom: 48px;
}

html body .p-l-48px {
  padding-left: 48px;
}

html body .p-r-48px {
  padding-right: 48px;
}

html body .m-t-49px {
  margin-top: 49px;
}

html body .m-b-49px {
  margin-bottom: 49px;
}

html body .m-l-49px {
  margin-left: 49px;
}

html body .m-r-49px {
  margin-right: 49px;
}

html body .p-t-49px {
  padding-top: 49px;
}

html body .p-b-49px {
  padding-bottom: 49px;
}

html body .p-l-49px {
  padding-left: 49px;
}

html body .p-r-49px {
  padding-right: 49px;
}

html body .m-t-50px {
  margin-top: 50px;
}

html body .m-b-50px {
  margin-bottom: 50px;
}

html body .m-l-50px {
  margin-left: 50px;
}

html body .m-r-50px {
  margin-right: 50px;
}

html body .p-t-50px {
  padding-top: 50px;
}

html body .p-b-50px {
  padding-bottom: 50px;
}

html body .p-l-50px {
  padding-left: 50px;
}

html body .p-r-50px {
  padding-right: 50px;
}

html body .normal-font-family {
  font-family: "Helvetica Neue", "Roboto", "Arial", sans-serif;
}

html body .condensed-font-family {
  font-family: "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}

html body .alegreya-font-family {
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}

html body .roboto-font-family {
  font-family: 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}

html body .font-size-4xs {
  font-size: 0.5rem;
}

html body .font-size-3xs {
  font-size: 0.625rem;
}

html body .font-size-2xs {
  font-size: 0.685rem;
}

html body .font-size-xs {
  font-size: 0.75rem;
}

html body .font-size-s {
  font-size: 0.875rem;
}

html body .font-size-m {
  font-size: 1rem;
}

html body .font-size-l {
  font-size: 1.125rem;
}

html body .font-size-xl {
  font-size: 1.25rem;
}

html body .font-size-2xl {
  font-size: 1.5rem;
}

html body .font-size-3xl {
  font-size: 1.875rem;
}

html body .font-size-4xl {
  font-size: 2.25rem;
}

html body .font-size-5xl {
  font-size: 3rem;
}

html body .font-size-6xl {
  font-size: 4rem;
}

html body .font-size-7xl {
  font-size: 5rem;
}

html body .text-white {
  color: white;
}

html body .text-black {
  color: black;
}

html body .text-gray {
  color: #171d28;
}

html body .text-lightgray {
  color: gray;
}

html body .text-green {
  color: green;
}

html body .text-red {
  color: red;
}

html body .bg-white {
  background-color: white;
}

html body .bg-black {
  background-color: black;
}

html body .bg-gray {
  background-color: #171d28;
}

html body .bg-lightgray {
  background-color: gray;
}

html body .bg-silver {
  background-color: silver;
}

html body .bg-lightsilver {
  background-color: whitesmoke;
}

html body .text-italic {
  font-style: italic;
}

html body .text-not-italic {
  font-style: normal;
}

html body .text-uppercase {
  text-transform: uppercase;
}

html body .text-lowercase {
  text-transform: lowercase;
}

html body .text-capitalize {
  text-transform: capitalize;
}

html body .text-normalcase {
  text-transform: none;
}

html body .text-underline {
  text-decoration: underline;
}

html body .text-no-underline {
  text-decoration: none;
}

html body .text-linethrough {
  text-decoration: line-through;
}

html body .text-left-align {
  text-align: left;
}

html body .text-right-align {
  text-align: right;
}

html body .text-center-align {
  text-align: center;
}

html body .font-weight-100 {
  font-weight: 100;
}

html body .font-weight-200 {
  font-weight: 200;
}

html body .font-weight-300 {
  font-weight: 300;
}

html body .font-weight-400 {
  font-weight: 400;
}

html body .font-weight-500 {
  font-weight: 500;
}

html body .font-weight-600 {
  font-weight: 600;
}

html body .font-weight-700 {
  font-weight: 700;
}

html body .font-weight-800 {
  font-weight: 800;
}

html body .font-weight-900 {
  font-weight: 900;
}

html body .font-weight-1000 {
  font-weight: 1000;
}

html body .font-spacing-tighter {
  letter-spacing: -0.05em;
}

html body .font-spacing-tight {
  letter-spacing: -0.025em;
}

html body .font-spacing-normal {
  letter-spacing: 0;
}

html body .font-spacing-wide {
  letter-spacing: 0.05em;
}

html body .font-spacing-wider {
  letter-spacing: 0.1em;
}

html body .font-spacing-widest {
  letter-spacing: 0.2em;
}

html body .line-height-tight {
  line-height: 0.9;
}

html body .line-height-normal {
  line-height: 1;
}

html body .line-height-wide {
  line-height: 1.2;
}

html body .line-height-wider {
  line-height: 1.5;
}

html body .line-height-widest {
  line-height: 2;
}

html body .horizontal-line {
  margin: 50px auto;
  width: 90%;
  border-style: none;
  border-bottom-style: solid;
  border-color: silver;
}

html body .no-horizontal-line {
  margin: 50px auto;
  width: 90%;
  border-style: none;
}

html body .button {
  width: 150px;
}

html body .button:hover {
  background-color: black;
}

html body .button.MuiButton-root.Mui-disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.26);
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.26);
}

html body .transparent-button {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  width: 150px;
}

html body .transparent-button:hover {
  color: white;
}

html body .red-transparent-button {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  color: red;
  width: 150px;
  box-shadow: none;
}

html body .red-transparent-button:hover {
  background-color: red;
  color: white;
}

html body .green-transparent-button {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  color: green;
  width: 150px;
  box-shadow: none;
}

html body .green-transparent-button:hover {
  background-color: green;
  color: white;
}

html body .pop-up .pop-up-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 700px) {
  html body .pop-up .pop-up-title .MuiIconButton-root {
    padding-right: 0px;
    padding-left: 0px;
  }
}

html body .pop-up .pop-up-title .pop-up-title-close {
  color: white;
}

html body .pop-up .pop-up-content {
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

html body .pop-up .pop-up-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #EEEEEE;
}

