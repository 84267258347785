.article-thumbnail3-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.article-thumbnail3-container:hover {
  cursor: pointer;
}

.article-thumbnail3-container .article-thumbnail3-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
}

.article-thumbnail3-container .article-thumbnail3-text-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.7)), color-stop(30%, rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0) 100%);
}

.article-thumbnail3-container .article-thumbnail3-text-container:hover {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.7)), color-stop(30%, rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.7)));
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0.7) 100%);
}

.article-thumbnail3-container .article-thumbnail3-text-container:hover .article-thumbnail3-text {
  display: none;
}

.article-thumbnail3-container .article-thumbnail3-text-container:hover .article-thumbnail3-button {
  display: block;
}

.article-thumbnail3-container .article-thumbnail3-text-container .article-thumbnail3-button {
  margin: auto;
  display: none;
}
