.sponsors-component4-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

@media (max-width: 1200px) {
  .sponsors-component4-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.sponsors-component4-container .sponsors-component4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1200px) {
  .sponsors-component4-container .sponsors-component4 {
    width: 100%;
  }
}

.sponsors-component4-container .sponsors-component4 .sponsors-component4-sponsor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: 30%;
}

.sponsors-component4-container .sponsors-component4 .sponsors-component4-sponsor :hover {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .sponsors-component4-container .sponsors-component4 .sponsors-component4-sponsor {
    width: 40%;
  }
}

.sponsors-component4-container .sponsors-component4 .sponsors-component4-sponsor .sponsors-component4-sponsor-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-height: 100px;
  max-width: 300px;
  -o-object-fit: contain;
     object-fit: contain;
}

@media (max-width: 700px) {
  .sponsors-component4-container .sponsors-component4 .sponsors-component4-sponsor .sponsors-component4-sponsor-logo {
    height: auto;
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
}

.sponsors-component4-container .sponsors-component4 .sponsors-component4-sponsor .sponsors-component4-sponsor-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
