@media (max-width: 700px) {
  .footer-seasons-container {
    display: none;
  }
}

@media (max-width: 700px) {
  .footer-articles-container {
    display: none;
  }
}

.footer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer-container .team-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 300px;
}

@media (max-width: 700px) {
  .footer-container .team-footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.footer-container .team-footer .team-footer-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  height: 80%;
  width: 20%;
}

@media (max-width: 700px) {
  .footer-container .team-footer .team-footer-links {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .team-footer .team-footer-links {
    margin-left: 50px;
  }
}

.footer-container .team-footer .team-footer-links .team-footer-link {
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 500;
  text-decoration: none;
  color: white;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.footer-container .team-footer .team-footer-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

@media (max-width: 700px) {
  .footer-container .team-footer .team-footer-content {
    padding-top: 40px;
  }
}

.footer-container .team-footer .team-footer-content .icon {
  height: 120px;
  max-width: 80%;
  -o-object-fit: contain;
     object-fit: contain;
}

.footer-container .team-footer .team-footer-content .name-icon {
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 500;
  text-decoration: none;
  color: white;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.footer-container .team-footer .team-footer-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  height: 70%;
  width: 20%;
}

@media (max-width: 700px) {
  .footer-container .team-footer .team-footer-social {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    margin: 0;
    height: 30%;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .team-footer .team-footer-social {
    margin-right: 50px;
  }
}

.footer-container .optimx-ad {
  background-color: #202020;
  z-index: 1000;
}

.footer-container .optimx-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 150px;
  background-color: #101010;
}

.footer-container .optimx-footer .optimx-footer-copyright {
  font-weight: 800;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  height: 60%;
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .optimx-footer-copyright {
    font-size: 0.8rem;
    margin-left: 20px;
    width: 30%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .optimx-footer-copyright {
    font-size: 1rem;
    margin-left: 50px;
    width: 30%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements .optimx-footer-agreement {
    font-size: 0.7rem !important;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements .optimx-footer-agreement {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements .optimx-footer-agreement-slash {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .optimx-footer-copyright .optimx-footer-agreements .optimx-footer-agreement-slash {
    display: none;
  }
}

.footer-container .optimx-footer .icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 60%;
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .icon-container {
    margin-right: 20px;
    width: 35%;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .icon-container {
    margin-right: 50px;
    width: 35%;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}

.footer-container .optimx-footer .icon-container .icon {
  height: 80px;
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .icon-container .icon {
    height: 50px;
    margin-right: 5px;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .icon-container .icon {
    height: 80px;
    margin-right: 5px;
  }
}

.footer-container .optimx-footer .icon-container .name {
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 800;
  text-decoration: none;
  color: white;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media (max-width: 700px) {
  .footer-container .optimx-footer .icon-container .name {
    font-size: 0.7rem;
    text-align: right;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .footer-container .optimx-footer .icon-container .name {
    font-size: 1rem;
    text-align: right;
  }
}
