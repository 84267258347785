.title3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100px;
}

@media (max-width: 700px) {
  .title3 .title3-title {
    margin-left: 0px !important;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .title3 .title3-title {
    margin-left: 50px !important;
  }
}

.title3 .title3-logo {
  height: 72px;
}

@media (max-width: 700px) {
  .title3 .title3-logo {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .title3 .title3-logo {
    margin-left: 50px !important;
  }
}
