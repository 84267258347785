.inactive {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  height: calc(100vh - 20px);
}

@media (max-width: 700px) {
  .inactive {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.inactive .inactive-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  width: 50%;
  height: 100%;
}

@media (max-width: 700px) {
  .inactive .inactive-left {
    display: none;
  }
}

.inactive .inactive-left .icon {
  max-height: 200px;
  max-width: 300px;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .inactive .inactive-left .icon {
    max-height: 100px;
    max-width: 200px;
  }
}

.inactive .inactive-right {
  padding-top: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 50%;
  height: 100%;
}

@media (max-width: 700px) {
  .inactive .inactive-right {
    padding-top: 20px;
    width: 100%;
  }
}

.inactive .inactive-right .inactive-right-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 5rem;
  margin: 0 20px;
  text-align: left;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .inactive .inactive-right .inactive-right-title {
    text-align: center;
  }
}

@media (max-width: 700px) {
  .inactive .inactive-right .inactive-right-title {
    text-align: center;
  }
}

.inactive .inactive-right .inactive-right-subtitle {
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 1.5rem;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .inactive .inactive-right .inactive-right-subtitle {
    text-align: center;
  }
}

@media (max-width: 700px) {
  .inactive .inactive-right .inactive-right-subtitle {
    text-align: center;
  }
}

.inactive .inactive-right .inactive-right-subtitle a {
  text-decoration: none;
  color: #2B61FE;
}

.inactive .inactive-right .inactive-right-textfield {
  width: 90%;
}

.inactive .inactive-right .inactive-right-textfield .MuiOutlinedInput-root {
  margin-top: 5px;
  margin-bottom: 0;
}

.inactive .inactive-right .inactive-right-textfield .MuiOutlinedInput-root fieldset {
  border-color: black;
}

.inactive .inactive-right .inactive-right-textfield .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: black;
}

.inactive .inactive-right .inactive-submit-buttons {
  margin-top: 10px;
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 1200px) {
  .inactive .inactive-right .inactive-submit-buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    height: 150px;
  }
}

.inactive .inactive-right .inactive-accordian {
  width: 80%;
  border-style: none;
  -webkit-box-shadow: 0 2.5px 2px rgba(0, 0, 0, 0.03);
          box-shadow: 0 2.5px 2px rgba(0, 0, 0, 0.03);
}

.inactive .inactive-right .inactive-accordian .inactive-accordian-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
