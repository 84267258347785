.title2-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  height: 75px;
}

.title2-container .title2-text:hover {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .title2-container .title2-text {
    font-size: 2.5rem;
  }
}

@media (max-width: 700px) {
  .title2-container .title2-text {
    font-size: 2rem;
  }
}
