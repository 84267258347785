.fab-component {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 120px;
  position: fixed;
  top: auto;
  right: 0;
  left: auto;
  margin-right: -20px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 101;
  border-radius: 15px;
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}

.fab-component.hide {
  right: -300px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.fab-component.ticket {
  bottom: 10px;
}

.fab-component.store {
  bottom: 80px;
}

.fab-component:hover {
  cursor: pointer;
}

@media (max-width: 700px) {
  .fab-component {
    display: none;
  }
}
