.navbar-container2 {
  margin: 0;
  width: 100%;
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-transition: height 0.15s ease-in-out;
  transition: height 0.15s ease-in-out;
}

.navbar-container2 p {
  margin: 0;
}

@media (max-width: 700px) {
  .navbar-container2 {
    height: 150px;
  }
}

@media (min-width: 1200px) {
  .navbar-container2.navbar-container2-scrolled {
    height: 100px;
  }
  .navbar-container2.navbar-container2-scrolled .navbar-whitebar-content {
    font-size: 1.1rem !important;
  }
}

.navbar-container2 .navbar-navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  height: 160px;
  -webkit-transition: height 0.15s ease-in-out;
  transition: height 0.15s ease-in-out;
}

@media (min-width: 1200px) {
  .navbar-container2 .navbar-navbar.navbar-navbar-scrolled {
    height: 100px;
  }
  .navbar-container2 .navbar-navbar.navbar-navbar-scrolled .nav-item {
    height: 50px !important;
    font-weight: 400 !important;
  }
  .navbar-container2 .navbar-navbar.navbar-navbar-scrolled .navbar-whitebar::after {
    height: 0px !important;
  }
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar {
    height: 110px;
  }
}

.navbar-container2 .navbar-navbar .icon-container {
  height: 100%;
  width: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1400px) {
  .navbar-container2 .navbar-navbar .icon-container {
    width: 15%;
  }
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .icon-container {
    width: 30%;
  }
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .icon-container {
    display: none;
  }
}

.navbar-container2 .navbar-navbar .icon-container .icon {
  max-height: 70%;
  max-width: 90%;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 80%;
  height: 100%;
}

@media (max-width: 1400px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars {
    width: 85%;
  }
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars {
    width: 100%;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar {
  background-color: white;
  width: 100%;
  height: 50%;
  position: relative;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar::before {
  height: 100%;
  width: 20px;
  bottom: 0;
  content: '';
  position: absolute;
  left: 5%;
  top: 0;
  -webkit-transform: skew(-45deg, 0);
          transform: skew(-45deg, 0);
  z-index: 100;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar::before {
    left: 7%;
  }
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar::before {
    display: none;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar::after {
  height: 100%;
  width: 1%;
  bottom: 0;
  content: '';
  position: absolute;
  left: 0px;
  top: 0;
  height: 0px;
  border-right: 80px solid transparent;
  border-top: 80px solid #FFFFFF;
  z-index: 100;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar::after {
    display: none;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content {
  z-index: 101;
  color: black;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-family: 'Alegreya Sans', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  text-transform: uppercase;
  font-size: 1.4rem;
  font-family: "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 800;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content {
    font-size: 1rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content .navbar-whitebar-teamname {
  margin-left: 10%;
  text-align: center;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content .navbar-whitebar-teamname {
    margin-left: 15%;
    margin-right: 10%;
  }
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content .navbar-whitebar-teamname {
    margin-left: 0;
    margin-right: 0;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content .navbar-whitebar-store {
  margin-right: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-whitebar .navbar-whitebar-content .navbar-whitebar-store {
    display: none;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems {
  width: 100%;
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  z-index: 101;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .mobile-icon-container {
  display: none;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .mobile-icon-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    max-width: 77%;
    height: 100%;
    padding-left: 20px;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .mobile-icon-container .mobile-icon {
  height: 40px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-normal {
  max-width: 85vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-normal {
    display: none;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-normal .nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1rem;
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.2);
  border-right-width: 1px;
  height: 80px;
  line-height: 1;
  border-radius: 0;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  -webkit-transition: height 0.15s ease-in-out;
  transition: height 0.15s ease-in-out;
}

@media (max-width: 1400px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-normal .nav-item {
    font-size: 0.8rem;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-normal .nav-item:hover {
  background-color: white;
  color: black !important;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-normal .nav-item .nav-item-with-drop-down {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down {
  display: none;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down {
    display: block;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down .nav-item {
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 {
  display: none;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-burger-button {
  position: fixed;
  width: 28px;
  height: 28px;
  right: 30px;
  top: 69px;
  -webkit-tap-highlight-color: transparent;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-burger-button {
    top: 107px;
    right: 30px;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-burger-bars {
  background: white;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-cross-button {
  width: 30px;
  height: 30px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-cross {
  background: black;
  display: none;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-overlay {
  background: transparent !important;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-menu-wrap {
  position: fixed;
  top: 150px;
  height: calc(100% - 150px) !important;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-menu-wrap {
    top: 200px;
    height: calc(100% - 200px) !important;
  }
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-menu {
  background: #f0f0f0;
  width: 100%;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .bm-item-list {
  height: 100% !important;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .nav-items-accordinan {
  position: unset;
  border-style: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .nav-items-accordinan .nav-items-accordinan-summary {
  border-style: none;
  padding-left: 0px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .nav-items-accordinan .nav-items-accordinan-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin: 0;
  padding: 0;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .accordian-nav-item-accordinan {
  position: unset;
  border-style: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
  padding-left: 20px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .accordian-nav-item-accordinan .accordian-nav-item-accordinan-summary {
  border-style: none;
  padding-left: 0px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .accordian-nav-item-accordinan .accordian-nav-item-accordinan-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin: 0;
  padding: 0;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .nav-item {
  font-family: 'Alegreya Sans', "HelveticaNeue-CondensedBold", 'Roboto Condensed', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  padding-left: 40px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .normal-nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .accordian-nav-item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 60px;
  width: 100%;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .nav-items-drop-down2-logo1 {
  width: 0px;
  height: 1px;
}

.navbar-container2 .navbar-navbar .navbar-horizontal-bars .navbar-navitems .nav-items-drop-down2 .nav-items-drop-down2-logo2 {
  padding-top: 50px;
  padding-bottom: 120px;
  width: 100px;
  height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  margin-left: auto;
  margin-right: auto;
  outline: none;
}

.navbar-container2 .navbar-socials-container {
  background-color: white;
  -webkit-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.04);
          box-shadow: 0 5px 7px rgba(0, 0, 0, 0.04);
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (min-width: 1200px) {
  .navbar-container2 .navbar-socials-container.navbar-socials-container-scrolled {
    display: none;
  }
}

.navbar-container2 .navbar-socials-container .navbar-socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 100%;
  margin-left: 30px;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-socials-container .navbar-socials {
    margin-left: 0px;
  }
}

.navbar-container2 .navbar-socials-container .navbar-socials .navbar-socials-buttons {
  background-color: transparent;
  cursor: pointer;
  border-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-socials-container .navbar-socials .navbar-socials-buttons {
    padding: 0 10px;
  }
}

.navbar-container2 .navbar-socials-container .navbar-socials .navbar-socials-buttons .navbar-socials-icon {
  height: 19px;
  margin-right: 20px;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-socials-container .navbar-socials .navbar-socials-buttons .navbar-socials-icon {
    margin-right: 5px;
  }
}

.navbar-container2 .navbar-socials-container .navbar-socials .navbar-socials-buttons p {
  font-family: 'Alegreya Sans', "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
}

@media (max-width: 1200px) {
  .navbar-container2 .navbar-socials-container .navbar-socials .navbar-socials-buttons p {
    display: none;
  }
}

.navbar-container2 .navbar-socials-container .navbar-shop-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  margin-right: 30px;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-socials-container .navbar-shop-container {
    margin-right: 0px;
  }
}

.navbar-container2 .navbar-socials-container .navbar-shop-container .navbar-shop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
  border-style: none;
  background-color: transparent;
}

.navbar-container2 .navbar-socials-container .navbar-shop-container .navbar-shop p {
  font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
  font-size: 15px;
}

@media (max-width: 700px) {
  .navbar-container2 .navbar-socials-container .navbar-shop-container .navbar-shop {
    padding: 0 5px;
  }
  .navbar-container2 .navbar-socials-container .navbar-shop-container .navbar-shop p {
    display: none;
  }
}

@media (max-width: 1200px) {
  .nav-item-cart .MuiBadge-badge {
    color: black !important;
    background-color: white !important;
  }
}

.nav-item-cart:hover {
  cursor: pointer;
}

.nav-item-cart-drawer .MuiPaper-root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 400px;
  background: #f0f0f0;
}

@media (max-width: 700px) {
  .nav-item-cart-drawer .MuiPaper-root {
    width: 100%;
  }
}

.nav-item-cart-drawer .nav-item-cart-topic {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 30px;
  min-height: 64px;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .nav-item-cart-drawer .nav-item-cart-topic {
    min-height: 64px;
  }
}

@media (max-width: 700px) {
  .nav-item-cart-drawer .nav-item-cart-topic {
    min-height: 56px;
  }
}

.nav-item-cart-drawer .nav-item-cart-price {
  width: 280px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.nav-item-cart-drawer .nav-item-cart-item {
  padding: 20px 50px;
  max-width: calc(100% - 100px);
  min-width: calc(100% - 100px);
  width: calc(100% - 100px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  border-bottom-style: solid;
  border-width: 1px;
  font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}

.nav-item-cart-drawer .nav-item-cart-item .nav-item-cart-item-label {
  text-align: left;
  font-weight: 300;
  font-size: 12px;
  border-style: solid;
  border-width: 1px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 10px;
  border-radius: 2px;
}

.nav-item-cart-drawer .nav-item-cart-item .nav-item-cart-item-image {
  padding: 10px 0px;
  max-height: 100px;
  border-radius: 10px;
  overflow: hidden;
  -o-object-fit: contain;
     object-fit: contain;
  margin-left: 0;
  margin-right: auto;
}

.nav-item-cart-drawer .nav-item-cart-item .nav-item-cart-item-title {
  text-align: left;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 0px;
  border-radius: 2px;
  text-transform: capitalize;
  line-height: 20px;
  letter-spacing: 0;
}

.nav-item-cart-drawer .nav-item-cart-item .nav-item-cart-item-quantity {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 10px;
}

.nav-item-cart-drawer .nav-item-cart-item .nav-item-cart-item-price {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 0px;
  border-radius: 2px;
  text-transform: capitalize;
  line-height: 20px;
  letter-spacing: 0;
}

.nav-item-cart-drawer .nav-item-cart-item .nav-item-cart-item-subprice {
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 0px;
  border-radius: 2px;
  text-transform: capitalize;
  line-height: 20px;
  letter-spacing: 0;
}
