.tickets-component1-container-container {
  max-width: 1200px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .tickets-component1-container-container .tickets-component1-container {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-title {
  font-size: 3.86rem;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-title {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-image {
  width: calc(100% - 40px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-image {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-text {
  overflow: hidden;
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-text a {
  word-break: break-all;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-text img {
    width: 300px;
  }
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-text figcaption {
    max-width: 300px;
  }
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-text iframe {
    max-width: 300px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-text table {
  border-collapse: collapse;
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-text table td {
  border-style: solid;
  border-width: 1px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-text {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 7px;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 600;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table {
    font-size: 0.8rem;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: lightgray;
  color: white;
  padding: 20px 40px;
  border-radius: 7px 7px 0 0;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container {
    padding: 10px 10px;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-description {
  width: 20%;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-description {
    width: 80px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-price {
  width: 20%;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-price {
    width: 50px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-quantity {
  width: 10%;
  min-width: 130px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-quantity {
    width: 70px;
    min-width: 70px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-total {
  width: 10%;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-header-container .tickets-component1-price-header-total {
    width: 50px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top-style: solid;
  border-width: 1px;
  margin-top: -1px;
  border-color: silver;
  padding: 10px 40px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container {
    padding: 10px 10px;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-description {
  width: 20%;
  text-transform: none;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-description {
    width: 80px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-price {
  width: 20%;
  font-size: 1rem;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-price {
    font-size: 0.8rem;
    width: 50px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-quantity {
  width: 10%;
  min-width: 130px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-quantity {
    width: 70px;
    min-width: 70px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-total {
  width: 10%;
  font-size: 1rem;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-table .tickets-component1-price-container .tickets-component1-price-total {
    font-size: 0.8rem;
    width: 50px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-subprice-footer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-subprice-footer-container .tickets-component1-subprice-footer-total-label {
  width: 30%;
  padding-top: 2px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-subprice-footer-container .tickets-component1-subprice-footer-total-label {
    width: 70%;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-subprice-footer-container .tickets-component1-subprice-footer-total {
  width: 10%;
  font-size: 1.4rem;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-subprice-footer-container .tickets-component1-subprice-footer-total {
    width: 30%;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-price-footer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 40px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-price-footer-container {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-price-footer-container .tickets-component1-price-footer-total-label {
  width: 10%;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-price-footer-container .tickets-component1-price-footer-total-label {
    width: 50%;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-price-footer-container .tickets-component1-price-footer-total {
  width: 10%;
  font-size: 1.7rem;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-price-footer-container .tickets-component1-price-footer-total {
    width: 30%;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 300px;
  height: 100px;
  margin-left: auto;
  margin-right: 20px;
}

@media (max-width: 700px) {
  .tickets-component1-container-container .tickets-component1-container .tickets-component1-buttons {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: auto;
  }
}

.tickets-component1-container-container .tickets-component1-container .tickets-component1-icon {
  height: 42px;
}

.pop-up-content-ticket-customer {
  height: 300px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
          justify-content: space-evenly !important;
}
