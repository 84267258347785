.textfield-primary-color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 10px;
}

.textfield-primary-color .textfield-title {
  text-transform: uppercase;
}

.textfield-primary-color p {
  color: gray;
}

.textfield-primary-color .MuiOutlinedInput-root {
  background-color: #f8f8f8;
  margin-top: 5px;
  margin-bottom: 0;
}

.textfield-primary-color .MuiOutlinedInput-root fieldset {
  border-color: black;
  border: none;
}

.textfield-primary-color .MuiOutlinedInput-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.textfield-primary-color .MuiOutlinedInput-root.Mui-error {
  border-color: red;
  border-style: solid;
  border-width: 1px;
}

.half-width {
  width: 48%;
}

@media (max-width: 700px) {
  .half-width {
    width: 100%;
  }
}
